import { useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const MAX_FRAME_HEIGHT = 392;

export const VideoDisplay: React.FC<{
  url: string;
  isVideoSupported: boolean;
  onLoad: () => void;
  style?: React.CSSProperties;
}> = ({ url, isVideoSupported, onLoad, style }) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoEl = useRef<HTMLVideoElement>(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) return;
    // if not supported, there is nothing to load
    if (!isVideoSupported) {
      setLoaded(true);
      onLoad();
    }
  }, [isVideoSupported, onLoad, loaded]);

  const onVideoClick = () => setVideoPlaying(!videoPlaying);

  if (isVideoSupported) {
    return (
      <VideoPlayer
        ref={videoEl}
        src={url}
        loop
        preload="metadata"
        /** We need both onCanPlay and onLoadedMetadata to support all browsers in all conditions (eg. datasaver=on) */
        onCanPlay={onLoad}
        onLoadedMetadata={onLoad}
        onPlay={() => setVideoPlaying(true)}
        onPause={() => setVideoPlaying(false)}
        onClick={onVideoClick}
        controls
        style={style}
      />
    );
  }

  return (
    <FallbackText style={style}>
      <span>
        Your video is being converted to a different format. Meanwhile, you could download your
        video from{" "}
        <Link to={url} target="_blank" rel="noreferrer">
          here
        </Link>
      </span>
    </FallbackText>
  );
};

const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  background-color: black;
`;

const FallbackText = styled.span`
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${MAX_FRAME_HEIGHT}px;
  color: #9b9b9b;
  border-radius: 0 0 5px 5px;
`;
