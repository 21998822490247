import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { prettifyNumber } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import type { CSSProperties } from "react";

export const Loader: React.FC<{
  message?: string;
  size?: number;
  hide?: boolean;
  progress?: number | null;
  rootClassName?: string;
  style?: CSSProperties;
}> = ({ message, size = 30, hide = false, progress, style, rootClassName = "" }) => {
  if (hide) return null;

  return (
    <Root style={style} className={rootClassName}>
      <CircularProgress size={size} style={{ color: palette.primary.red }} />

      {typeof progress === "number" ? (
        <Message>{prettifyNumber(progress, { percentages: true })}</Message>
      ) : null}

      {message ? <Message>{message}</Message> : null}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

const Message = styled.span`
  max-width: 200px;
  word-wrap: break-word;
  text-align: center;
`;
