import type { FC } from "react";
import { Navigate, generatePath, useParams } from "react-router-dom";

export const NavigateWithParams: FC<{
  to: string;
  replace?: boolean;
  state?: any;
}> = ({ to, replace, state }) => {
  const params = useParams();
  const redirectWithParams = generatePath(to, params);

  return <Navigate to={redirectWithParams} replace={replace} state={state} />;
};
