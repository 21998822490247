import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

import { CustomLink } from "./CustomLink";

export const Footer: React.FC = () => {
  return (
    <Root>
      <CustomLink className="left-align" url="https://relatable.me">
        Relatable
      </CustomLink>
      <CustomLink url="https://relatable.me">About Relatable</CustomLink>
      <CustomLink url="https://creator.relatable.me/privacy">Privacy policy</CustomLink>
    </Root>
  );
};

const Root = styled.footer`
  width: calc(100% - 30px);
  margin-top: 40px;
  border-top: 1px solid #cacaca;
  display: flex;
  justify-content: flex-end;
  color: ${palette.gray[50]};
  font-size: 0.625rem;
  padding: 2px 0px;

  > .left-align {
    margin-right: auto;
    margin-left: 0px;
  }

  * {
    margin-left: 6px;
  }
`;
