import { useParams } from "react-router-dom";

import { useUser } from "components/hooks/useUser";
import type { CONTENT_APPROVAL_STEP } from "modules/approval/components/ContentApproval/Stepper/StepperContainer";
import { getTypeLabel } from "modules/approval/components/ContentApproval/utils";

import type { IBotComment } from "../botComments/types";
import { useContentMediaQuery, useContentStatusQuery } from "../generated";
import { StatusChecklist } from "./StatusChecklist";
import { getFormattedLogs } from "./getFormattedLogs";

export const StatusChecklistContainer: React.FC<{
  activeStep: CONTENT_APPROVAL_STEP;
  botComments: IBotComment[];
  slideIndex: number;
  loading: boolean;
}> = ({ activeStep, botComments, slideIndex, loading }) => {
  const user = useUser();

  const { settingId } = useParams<{ settingId: string }>();

  const {
    data: { settings } = {}
  } = useContentStatusQuery({
    variables: { settingId: Number(settingId) }
  });

  const contentId = settings?.contents?.[0]?.id;

  const {
    data: { medias } = {}
  } = useContentMediaQuery({
    variables: { contentId: contentId ?? 0 },
    skip: !contentId
  });

  if (!settings || !medias) return null;

  const {
    contents: [contentStatus],
    type,
    number_of_posts: requiredNumberOfPosts
  } = settings;

  const activeMedia = medias[slideIndex];

  const singularTypeLabel = getTypeLabel({
    type,
    requiredNumberOfPosts,
    shouldReturnPluralForm: false
  });

  const { content_preapproval_caption } = contentStatus;

  const detailLogs = [
    ...getFormattedLogs(
      (activeStep === "upload" ? activeMedia?.logs : content_preapproval_caption?.logs) ?? [],
      {
        target: activeStep === "upload" ? "media" : "caption",
        creator: user,
        typeLabel: singularTypeLabel
      }
    )
  ].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

  return <StatusChecklist loading={loading} botComments={botComments} detailLogs={detailLogs} />;
};
