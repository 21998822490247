import { createTheme } from "@mui/material";

import { palette } from "./Palette";

export const theme = createTheme({
  palette: {
    primary: {
      main: palette.primary.red
    },
    secondary: {
      main: palette.gray.black
    },
    error: {
      main: palette.primary.gold
    },
    warning: {
      main: palette.secondary.gold
    },
    info: {
      main: palette.secondary.blue
    },
    success: {
      main: palette.primary.green
    }
  }
});
