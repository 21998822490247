import type { CSSProperties, FC, ReactNode } from "react";

import { Input, InputAdornment, InputLabel, TextField, type TextFieldProps } from "@mui/material";

type InputProps = {
  label?: string;
  value?: string;
  onChange?: (v: string) => void;
  onBlur?(): void;
  style?: CSSProperties;
  type?: "number" | "email";
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  title?: string;
};

export const TextInput: FC<
  InputProps & {
    multiline?: boolean;
    size?: "small";
    minRows?: number;
    maxRows?: number;
    inputProps?: TextFieldProps["inputProps"];
    inputWrapperProps?: TextFieldProps["InputProps"];
    color?: TextFieldProps["color"];
    id?: string;
  }
> = ({ onChange, inputProps = {}, inputWrapperProps = {}, value, ...rest }) => (
  <TextField
    value={value || ""}
    aria-label={rest.label}
    inputProps={{
      ...inputProps,
      style: { height: "auto", ...(inputProps.style || {}) }
    }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    InputProps={inputWrapperProps}
    {...rest}
    onChange={e => onChange?.(e.target.value)}
  />
);

export const TextInputWithIcon: FC<
  InputProps & {
    iconPosition?: "start" | "end";
    icon: ReactNode;
    id?: string;
  }
> = ({
  iconPosition = "end",
  icon,
  id = "input-with-icon-adornment",
  label,
  onChange,
  ...rest
}) => (
  <>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Input
      id={id}
      endAdornment={
        iconPosition === "end" ? (
          <InputAdornment position="end" style={{ cursor: "pointer" }}>
            {icon}
          </InputAdornment>
        ) : null
      }
      startAdornment={
        iconPosition === "start" ? <InputAdornment position="start">{icon}</InputAdornment> : null
      }
      onChange={e => onChange?.(e.target.value)}
      {...rest}
    />
  </>
);
