import styled from "@emotion/styled";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";

import type { CONTENT_APPROVAL_STEP } from "../StepperContainer";
import { BriefTip } from "./BriefTip";
import { CaptionContainer } from "./Caption/CaptionContainer";
import { ContentItemComment } from "./ContentItemComment/ContentItemComment";
import { MediaUploadContainer } from "./MediaUpload/MediaUploadContainer";
import { StatusChecklistContainer } from "./StatusChecklist/StatusChecklistContainer";
import type { IBotComment } from "./botComments/types";
import type { ContentMediaQuery, ContentStatusQuery } from "./generated";

export const Step: React.FC<{
  activeStep: CONTENT_APPROVAL_STEP;
  briefUrl: string;
  botComments: IBotComment[];
  content: NonNullable<ContentStatusQuery["settings"]>["contents"][0];
  medias: ContentMediaQuery["medias"];
  onUpdateSlideIndex: (index: number) => void;
  slideIndex: number;
  contentState: CONTENT_APPROVAL_STATE;
  loadingAddComment: boolean;
}> = ({
  activeStep,
  briefUrl,
  botComments,
  content,
  medias,
  onUpdateSlideIndex,
  slideIndex,
  contentState,
  loadingAddComment
}) => {
  const activeMedia = medias[slideIndex] ?? null;
  const { content_preapproval_caption: contentCaption } = content;
  return (
    <Root>
      {activeStep === "upload" && (
        <>
          <MediaUploadContainer
            contentState={contentState}
            slideIndex={slideIndex}
            onUpdateSlideIndex={onUpdateSlideIndex}
          />
          {Boolean(activeMedia?.id) && <ContentItemComment mediaId={activeMedia.id} />}
        </>
      )}
      {activeStep === "caption" && (
        <>
          <CaptionContainer />
          {contentCaption?.id ? <ContentItemComment captionId={contentCaption.id} /> : null}
        </>
      )}

      <StatusChecklistContainer
        activeStep={activeStep}
        botComments={botComments}
        slideIndex={slideIndex}
        loading={loadingAddComment}
      />

      <BriefTip briefUrl={briefUrl} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;
