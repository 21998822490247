import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

import type { UseUploadMediaParams } from "modules/approval/components/ContentApproval/Stepper/useUploadMedia";
import { getAcceptedFiles } from "modules/approval/components/ContentApproval/utils";

import type { ContentMediaQuery, ContentStatusQuery } from "../generated";
import { Carousel } from "./common/Carousel";
import { FilePicker } from "./common/FilePicker";
import { NoImage } from "./common/NoImage";

export const MediaUpload: React.FC<{
  contentSetting: ContentStatusQuery["settings"];
  medias: ContentMediaQuery["medias"];
  progress: number | null;
  slideIndex: number;
  uploading: boolean;
  editable: boolean;
  onUpdateSlideIndex: (index: number) => void;
  onFileUploadChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  uploadParams: UseUploadMediaParams;
}> = ({
  contentSetting,
  medias,
  progress,
  slideIndex,
  uploading,
  editable,
  onUpdateSlideIndex,
  onFileUploadChange,
  uploadParams
}) => {
  if (!contentSetting) return null;

  const { type } = contentSetting;

  return (
    <Root>
      {medias.length !== 0 || uploading ? (
        <Carousel
          medias={medias}
          progress={progress}
          slideIndex={slideIndex}
          uploading={uploading}
          onUpdateSlideIndex={onUpdateSlideIndex}
          contentType={type}
          editable={editable}
          acceptedFiles={getAcceptedFiles(type)}
          onFileUploadChange={onFileUploadChange}
          uploadParams={uploadParams}
        />
      ) : (
        <NoImage>
          <FilePicker
            onFileUploadChange={onFileUploadChange}
            acceptedFiles={getAcceptedFiles(type)}
          />
          <SCUploadInstruction>Upload content for approval</SCUploadInstruction>
        </NoImage>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  background-color: ${palette.gray[10]};
  padding: 16px 0px;
  max-width: 400px;
  border-bottom: 2px solid ${palette.gray[90]};
  margin: 0px;
`;

const SCUploadInstruction = styled.span`
  margin-top: 12px;
  text-align: center;
  color: ${palette.gray.black};
`;
