import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";
import { creatorDone, creatorEditing, normalizeState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";

import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { CONTENT_APPROVAL_STATE_CREATOR_LABELS } from "../utils";
import type { CONTENT_APPROVAL_STEP } from "./StepperContainer";

export const Header: React.FC<{
  activeStep: CONTENT_APPROVAL_STEP;
  nextStep: CONTENT_APPROVAL_STEP | null;
  contentState: CONTENT_APPROVAL_STATE;
  onNextStep: () => void;
  onPrevStep: () => void;
  canProceed: boolean;
}> = ({ activeStep, onPrevStep, onNextStep, contentState, canProceed, nextStep }) => {
  const editable = creatorEditing(contentState);

  const title = (() => {
    if (editable) {
      if (activeStep === "upload") {
        return nextStep === "caption" ? "Step 1/2 - Upload" : "Upload";
      }
      return "Step 2/2 - Caption";
    }
    if (contentState in CONTENT_APPROVAL_STATE_CREATOR_LABELS) {
      return CONTENT_APPROVAL_STATE_CREATOR_LABELS[contentState];
    }
    return null;
  })();

  const canProceedToCaption = !editable || canProceed;
  const submitDisabled = !canProceed || creatorDone(contentState);

  return (
    <Root>
      <Back onClick={onPrevStep}>
        <NavigateBefore style={{ marginRight: 5 }} />
        Back
      </Back>

      <Title $state={normalizeState(contentState)}>{title}</Title>

      <Tooltip title={!canProceed ? "Make sure that all details are completed" : ""}>
        {nextStep === "caption" ? (
          <Next
            $disabled={!canProceedToCaption}
            onClick={() => (canProceedToCaption ? onNextStep() : null)}
          >
            Caption
            <NavigateNext style={{ marginLeft: 5 }} />
          </Next>
        ) : (
          <Next
            $highlighted={editable}
            $disabled={submitDisabled}
            onClick={() => (canProceed ? onNextStep() : null)}
            title={
              submitDisabled && !creatorEditing(contentState) ? "Content was already submitted" : ""
            }
          >
            Submit
            <NavigateNext style={{ marginLeft: 5 }} />
          </Next>
        )}
      </Tooltip>
    </Root>
  );
};

const Root = styled.header`
  width: 100%;
  max-width: 400px;
  position: fixed;
  top: 0;
  z-index: 2;
  height: 70px;
  background-color: #ffffff;
  font-size: 15px;
  padding: 0px 20px;
  padding-top: 20px;
  display: flex;
  align-items: center;
`;

const Title = styled.span<{ $state: CONTENT_APPROVAL_STATE }>`
  text-decoration: none;
  color: ${palette.gray.black};
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin: auto;
  padding: 4px 12px;

  ${({ $state }) => {
    if ([CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD as string | null].includes($state)) {
      return "";
    }

    const backgroundColor = (() => {
      if ($state === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING) {
        return palette.primary.green;
      }
      if ($state === CONTENT_APPROVAL_STATE.MANAGER_REJECTED) return palette.primary.gold;
      return palette.primary.red;
    })();

    return `
        color: ${palette.gray.white};
        border-radius: 16px;
        background: ${backgroundColor};
      `;
  }}
`;

const NavButton = styled.span`
  width: 85px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Back = styled(NavButton)`
  color: ${palette.gray[60]};
  text-align: left;
  display: flex;
  `;

const Next = styled(NavButton)<{ $disabled: boolean; $highlighted?: boolean }>`
  display: flex;
  justify-content: flex-end;
  color: ${({ $highlighted }) => ($highlighted ? palette.primary.red : palette.gray.black)};
  opacity: ${({ $disabled }) => ($disabled ? 0.2 : 1)};
  font-weight: 700;
  &:hover {
    opacity: ${({ $disabled }) => ($disabled ? 0.2 : 1)};
  }
`;
