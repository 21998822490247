/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { Alert, type AlertColor, Snackbar, type SnackbarOrigin } from "@mui/material";
import { uuidV4 } from "@relatable/helpers";

export interface SnackbarOptions {
  verticalAnchor?: SnackbarOrigin["vertical"];
  horizontalAnchor?: SnackbarOrigin["horizontal"];
}

export const SnackbarContext = createContext({
  success: (msg: string, opt?: SnackbarOptions) => {},
  error: (msg: string, opt?: SnackbarOptions) => {},
  info: (msg: string, opt?: SnackbarOptions) => {}
});

export interface SnackbarMessage {
  type: "info" | "success" | "error";
  msg: string;
  opt: SnackbarOptions;
}

export const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [messages, setMessages] = useState<Record<string, SnackbarMessage>>({});

  const addMessage = useCallback((msg: SnackbarMessage) => {
    setMessages(prev => ({ ...prev, [uuidV4()]: msg }));
  }, []);

  const removeMessage = useCallback((key: string) => {
    setMessages(prev => Object.fromEntries(Object.entries(prev).filter(([k]) => k !== key)));
  }, []);

  const contextValue = useMemo(
    () => ({
      success: (msg: string, opt?: SnackbarOptions) =>
        addMessage({ type: "success", msg, opt: opt ?? {} }),
      error: (msg: string, opt?: SnackbarOptions) =>
        addMessage({ type: "error", msg, opt: opt ?? {} }),
      info: (msg: string, opt?: SnackbarOptions) =>
        addMessage({ type: "info", msg, opt: opt ?? {} })
    }),
    [addMessage]
  );

  const elements = useMemo(
    () =>
      Object.entries(messages).map(([key, message], index) => {
        const verticalAnchor = message.opt?.verticalAnchor ?? "bottom";
        const horizontalAnchor =
          (() => {
            if (message.opt?.horizontalAnchor) return message.opt.horizontalAnchor;
            switch (message.type) {
              case "success":
                return "right";
              case "error":
                return "left";
              default:
                return "center";
            }
          })() ?? "center";

        let offset = 65 * index;
        if (verticalAnchor === "bottom") offset *= -1;

        return (
          <AlertSnackbar
            key={key}
            messageKey={key}
            type={message.type}
            transform={
              horizontalAnchor === "center"
                ? `translate(-50%, ${offset}px)`
                : `translateY(${offset}px)`
            }
            message={message.msg}
            horizontalAnchor={horizontalAnchor}
            verticalAnchor={message.opt?.verticalAnchor ?? "bottom"}
            onClose={removeMessage}
          />
        );
      }),
    [messages, removeMessage]
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      {elements}
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);

const AlertSnackbar: FC<{
  messageKey: string;
  message: string;
  onClose(key: string): void;
  transform: string;
  type: AlertColor;
  verticalAnchor: SnackbarOrigin["vertical"];
  horizontalAnchor: SnackbarOrigin["horizontal"];
}> = ({ onClose, messageKey, message, transform, type, verticalAnchor, horizontalAnchor }) => {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    onClose(messageKey);
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      onClose(messageKey);
    }, 10000);

    return () => clearTimeout(interval);
  }, [onClose, messageKey]);

  return (
    <Snackbar
      style={{ transform }}
      anchorOrigin={{
        vertical: verticalAnchor,
        horizontal: horizontalAnchor
      }}
      open={Boolean(message)}
      onClose={handleClose}
    >
      <Alert variant="filled" onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};
