import type { Platform_Enum } from "@relatable/gql/generated-base";

/** For use as labels visible on the UI. Also check getUserKey() */
export const getUserLabel = (
  user?: {
    user_instagram?: { username?: string | null } | null;
    user_tiktok?: { username?: string | null } | null;
    user_snapchat?: { username?: string | null } | null;
    user_youtube?: {
      // username might be null at some channels
      username?: string | null;
      channel_title?: string | null;
    } | null;
  } | null
) =>
  user?.user_instagram?.username ??
  user?.user_tiktok?.username ??
  user?.user_snapchat?.username ??
  user?.user_youtube?.username ?? // at YT the username is optional
  user?.user_youtube?.channel_title ??
  "anonymous";

/** For use as unique primary keys. Also check getUserLabel() */
export const getUserKey = (
  user?: {
    user_instagram?: { username?: string | null } | null;
    user_tiktok?: { username?: string | null } | null;
    user_snapchat?: { username?: string | null } | null;
    // At YT the only stable primary key is channel_id. username is not consistent enough
    user_youtube?: { channel_id?: string | null } | null;
  } | null
) => {
  const userId =
    user?.user_instagram?.username ??
    user?.user_tiktok?.username ??
    user?.user_snapchat?.username ??
    user?.user_youtube?.channel_id ??
    null;

  // TODO: Throw instead of logging
  if (!userId) console.error(`Cannot find user key at ${user}!`);

  return userId;
};

export const getUserLink = (
  user?: {
    user_instagram?: { username?: string | null } | null;
    user_tiktok?: { username?: string | null } | null;
    user_snapchat?: { username?: string | null } | null;
    user_youtube?: { username?: string | null; channel_id?: string | null } | null;
  } | null
): string => {
  if (user?.user_instagram) {
    return `https://instagram.com/${user.user_instagram.username}`;
  }

  if (user?.user_youtube) {
    if (user.user_youtube?.username) {
      return `https://youtube.com/@${user.user_youtube.username}`;
    }
    return `https://youtube.com/channel/${user.user_youtube.channel_id}`;
  }

  if (user?.user_tiktok) {
    return `https://tiktok.com/@${user.user_tiktok.username}`;
  }

  if (user?.user_snapchat) {
    return `https://snapchat.com/add/${user.user_snapchat.username}`;
  }

  throw new Error("Cannot generate link to user profile");
};

export const getUserProfilePicture = (
  user?: {
    user_instagram?: {
      internal_profile_picture?: string | null;
      profile_picture?: string | null;
    } | null;
    user_tiktok?: { profile_picture?: string | null } | null;
    user_youtube?: { thumbnail?: string | null } | null;
    user_snapchat?: { profile_picture?: string | null } | null;
  } | null
) =>
  user?.user_instagram?.internal_profile_picture ??
  user?.user_instagram?.profile_picture ??
  user?.user_tiktok?.profile_picture ??
  user?.user_youtube?.thumbnail ??
  user?.user_snapchat?.profile_picture ??
  "";

export const getUserFollowedBy = (user: {
  user_instagram?: { followed_by?: number | null } | null;
  user_tiktok?: { followed_by?: number | null } | null;
  user_youtube?: { subscribers?: string | null } | null;
  user_snapchat?: { subscribers?: number | null } | null;
}): number | null => {
  const val =
    user.user_instagram?.followed_by ??
    user.user_tiktok?.followed_by ??
    user.user_youtube?.subscribers ??
    user.user_snapchat?.subscribers;

  return val ? Number(val) : null;
};

export const getUserFollows = (user: {
  user_instagram?: { follows?: number | null } | null;
  user_tiktok?: { follows?: number | null } | null;
  user_youtube?: { median_views?: number | null } | null;
}): number | null => {
  const val =
    user.user_instagram?.follows ?? user.user_tiktok?.follows ?? user.user_youtube?.median_views;

  return val ? Number(val) : null;
};

// biome-ignore lint/complexity/noBannedTypes:
type AnyUserObject = {};

export const getUserPlatform = (user: {
  user_instagram?: AnyUserObject | null;
  user_tiktok?: AnyUserObject | null;
  user_snapchat?: AnyUserObject | null;
  user_youtube?: AnyUserObject | null;
}): Platform_Enum => {
  if (user.user_instagram) return "instagram";
  if (user.user_youtube) return "youtube";
  if (user.user_tiktok) return "tiktok";
  if (user.user_snapchat) return "snapchat";
  throw new Error("Invalid platform");
};
