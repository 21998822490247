import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserByEncodedIdQueryVariables = Types.Exact<{
  encodedId: Types.Scalars['String']['input'];
  campaignStub: Types.Scalars['String']['input'];
}>;


export type UserByEncodedIdQuery = { __typename?: 'Query', agreementDataByEncodedId: { __typename?: 'UserByEncodedId', id: number, first_name: string, userLabel: string, isSocialMediaTokenValid: boolean, platform: Types.Platform_Enum, agreementUrl?: string | null, isAgreementSigned: boolean, isAgreementReady: boolean, skipAPIVerification: boolean, logisticsEnabled: boolean, gigapayId?: string | null, gigapayStatus?: string | null, scrive_agreement_status?: string | null, scrive_agreement_id?: string | null, email: string, paymentStatus?: Types.Payment_Status_Enum | null, profile?: { __typename?: 'Profile', id: number, address_country?: string | null, address_post_code?: string | null, address_meta?: string | null, address_city?: string | null, address_state?: string | null, phone_number?: string | null, last_name: string, company_name?: string | null, company_id?: string | null, company_address?: string | null, personal_id?: string | null } | null } };

export type UpdateUserProfileMutationVariables = Types.Exact<{
  object: Types.Users_Profile_Insert_Input;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile?: void | null };

export type GigaPayRegisterUserMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  country: Types.Scalars['String']['input'];
}>;


export type GigaPayRegisterUserMutation = { __typename?: 'Mutation', gigaPayRegisterUser?: void | null };

export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Users_Set_Input;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', update_users_by_pk?: { __typename?: 'users', id: number } | null };


export const UserByEncodedIdDocument = gql`
    query userByEncodedId($encodedId: String!, $campaignStub: String!) {
  agreementDataByEncodedId(encodedId: $encodedId, campaignStub: $campaignStub) {
    id
    first_name
    userLabel
    isSocialMediaTokenValid
    platform
    agreementUrl
    isAgreementSigned
    isAgreementReady
    skipAPIVerification
    logisticsEnabled
    gigapayId
    gigapayStatus
    scrive_agreement_status
    scrive_agreement_id
    email
    paymentStatus
    profile {
      id
      address_country
      address_post_code
      address_meta
      address_city
      address_state
      phone_number
      last_name
      company_name
      company_id
      company_address
      personal_id
    }
  }
}
    `;

/**
 * __useUserByEncodedIdQuery__
 *
 * To run a query within a React component, call `useUserByEncodedIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEncodedIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEncodedIdQuery({
 *   variables: {
 *      encodedId: // value for 'encodedId'
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useUserByEncodedIdQuery(baseOptions: Apollo.QueryHookOptions<UserByEncodedIdQuery, UserByEncodedIdQueryVariables> & ({ variables: UserByEncodedIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByEncodedIdQuery, UserByEncodedIdQueryVariables>(UserByEncodedIdDocument, options);
      }
export function useUserByEncodedIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByEncodedIdQuery, UserByEncodedIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByEncodedIdQuery, UserByEncodedIdQueryVariables>(UserByEncodedIdDocument, options);
        }
export function useUserByEncodedIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserByEncodedIdQuery, UserByEncodedIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserByEncodedIdQuery, UserByEncodedIdQueryVariables>(UserByEncodedIdDocument, options);
        }
export type UserByEncodedIdQueryHookResult = ReturnType<typeof useUserByEncodedIdQuery>;
export type UserByEncodedIdLazyQueryHookResult = ReturnType<typeof useUserByEncodedIdLazyQuery>;
export type UserByEncodedIdSuspenseQueryHookResult = ReturnType<typeof useUserByEncodedIdSuspenseQuery>;
export type UserByEncodedIdQueryResult = Apollo.QueryResult<UserByEncodedIdQuery, UserByEncodedIdQueryVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($object: users_profile_insert_input!) {
  updateUserProfile(object: $object)
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const GigaPayRegisterUserDocument = gql`
    mutation gigaPayRegisterUser($email: String!, $country: String!) {
  gigaPayRegisterUser(email: $email, country: $country)
}
    `;
export type GigaPayRegisterUserMutationFn = Apollo.MutationFunction<GigaPayRegisterUserMutation, GigaPayRegisterUserMutationVariables>;

/**
 * __useGigaPayRegisterUserMutation__
 *
 * To run a mutation, you first call `useGigaPayRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGigaPayRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gigaPayRegisterUserMutation, { data, loading, error }] = useGigaPayRegisterUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGigaPayRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<GigaPayRegisterUserMutation, GigaPayRegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GigaPayRegisterUserMutation, GigaPayRegisterUserMutationVariables>(GigaPayRegisterUserDocument, options);
      }
export type GigaPayRegisterUserMutationHookResult = ReturnType<typeof useGigaPayRegisterUserMutation>;
export type GigaPayRegisterUserMutationResult = Apollo.MutationResult<GigaPayRegisterUserMutation>;
export type GigaPayRegisterUserMutationOptions = Apollo.BaseMutationOptions<GigaPayRegisterUserMutation, GigaPayRegisterUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: Int!, $set: users_set_input!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;