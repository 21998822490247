import styled from "@emotion/styled";

import { Footer } from "components/Layout/Footer";
import { Header } from "components/Layout/Header";
import { UserCard } from "components/Layout/UserCard";

import type { CreatorDashboardQueryHookResult } from "./generated";

export const Overview: React.FC<{
  user?: NonNullable<CreatorDashboardQueryHookResult["data"]>["creatorDashboard"];
}> = ({ user }) => {
  return (
    <Root>
      <Header title="Account insights" />
      <UserCard
        profilePicture={user?.profile_picture ?? user?.thumbnail ?? ""}
        username={user?.username || ""}
      />

      <Footer />
    </Root>
  );
};

const Root = styled.div`
  padding-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
