import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";

import type { IBotComment } from "./types";

export function getBotCommentsForActiveMedia({
  activeMediaState,
  contentState,
  typeLabel
}: {
  activeMediaState: CONTENT_APPROVAL_STATE;
  contentState: CONTENT_APPROVAL_STATE;
  typeLabel: string;
}): IBotComment[] {
  const botComments: IBotComment[] = [];

  if (contentState === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING) {
    botComments.push({
      isError: false,
      label: `This ${typeLabel} is good to go. Include this detail in your post.`
    });
  } else if (activeMediaState === CONTENT_APPROVAL_STATE.MANAGER_REJECTED) {
    botComments.push({
      isError: true,
      label: `This ${typeLabel} needs updates. Please see the comments on further instructions and replace the content if needed.`
    });
  } else {
    botComments.push({
      isError: false,
      label: `So far so good. No updates needed for this ${typeLabel} at the moment. We’ll let you know the final review after reviewing with the client.`
    });
  }

  return botComments;
}
