import type { CSSProperties, FC, ReactElement } from "react";

import { Checkbox as CheckboxMui, FormControlLabel } from "@mui/material";

export const Checkbox: FC<{
  label: string | ReactElement;
  checked: boolean;
  onChange: (checked: boolean) => void;
  indeterminate?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
}> = ({ label, checked, onChange, indeterminate, style, disabled }) => (
  <FormControlLabel
    label={label}
    style={style}
    control={
      <CheckboxMui
        disabled={disabled}
        indeterminate={indeterminate}
        checked={checked}
        onChange={(_e, value) => onChange(value)}
      />
    }
  />
);
