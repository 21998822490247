import { forwardRef, useImperativeHandle, useRef } from "react";

import styled from "@emotion/styled";
import { Add } from "@mui/icons-material";
import { IconButton } from "@relatable/ui/IconButton";
import { palette } from "@relatable/ui/Palette";

export const FilePicker = forwardRef<
  { open(): void },
  {
    invisible?: boolean;
    disabled?: boolean;
    acceptedFiles: string[];
    onFileUploadChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }
>(({ invisible, disabled, acceptedFiles, onFileUploadChange }, forwardedRef) => {
  const fileInput = useRef<HTMLInputElement>(null);

  useImperativeHandle(
    forwardedRef,
    () => {
      return {
        open() {
          fileInput.current?.click();
        }
      };
    },
    []
  );

  if (disabled) {
    return null;
  }

  const fileInputControl = (
    <>
      <input type="hidden" name="filepicker" value="" />
      <FileInput
        id="file"
        type="file"
        name="filepicker"
        multiple
        accept={acceptedFiles.join(",")}
        disabled={disabled}
        onChange={e => onFileUploadChange(e)}
        ref={fileInput}
      />
    </>
  );

  if (invisible) {
    return fileInputControl;
  }

  return (
    <Root>
      {fileInput.current !== undefined && (
        <ActionIconButton onClick={() => fileInput.current?.click()} disabled={disabled}>
          <Add />
        </ActionIconButton>
      )}
      {fileInputControl}
    </Root>
  );
});

const Root = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 40px;
  width: 40px;
`;

const ActionIconButton = styled(IconButton)`
  border-radius: 50%;

  && {
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.23);
    transition-timing-function: ease-out;
    transition: all 0.25s;

    background-color: ${palette.gray.black} !important;
    color: #ffffff;
    padding: 0px;

    &:hover {
      transform: scale(1.07);
      box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.23);
    }
  }
`;

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
