import { splitTagsBySpace } from "modules/approval/lib/sponsorshipTags";

import type { IBotComment } from "./types";

const MIN_CAPTION_LENGTH = 10;

export const getBotCommentsForCaption = ({
  caption,
  hashtags
}: {
  caption: string;
  hashtags: string;
}) => {
  const botComments = [] as IBotComment[];

  const requiredTags = splitTagsBySpace(hashtags);

  const missingTags = requiredTags.filter(
    rt => caption.toLowerCase().indexOf(rt.toLowerCase()) === -1
  );

  if (caption === "" || caption === hashtags) {
    botComments.push({ isError: true, label: "Please add the caption" });
  }

  const captionWithoutTags = requiredTags
    .reduce((acc, curr) => acc.replace(curr, " ").trim(), caption.toLowerCase())
    .trim();

  if (captionWithoutTags.length < MIN_CAPTION_LENGTH) {
    botComments.push({
      isError: true,
      label: "Caption is too short"
    });
  }

  if (missingTags.length) {
    botComments.push({
      isError: true,
      label: `You must include these hashtags in your caption: ${missingTags.join(" ")}`
    });
  }

  if (botComments.length === 0) {
    botComments.push({ isError: false, label: "The caption meets the requirements" });
  }

  return botComments;
};
