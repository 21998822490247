import { Component, type FC, type ReactNode } from "react";

import styled from "@emotion/styled";

const ErrorPage: FC<{ message?: string; description?: string }> = ({
  message = "Something went wrong.. Please retry",
  description
}) => (
  <Root>
    <h1>😕</h1>
    <p>{message}</p>
    {description && <p>{description}</p>}
  </Root>
);

const Root = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const firstThreeLines = (x: string) => x.split("\n").slice(0, 3).join("\n");

const serializeError = (error: Error) => {
  return `[build ${process.env.COMMIT_HASH} ${process.env.BUILD_DATE}]\n${error.message}\n${error.stack}`;
};

export class ErrorBoundary extends Component<
  { children: ReactNode; onError: (error: string) => void },
  { hasError: boolean }
> {
  private bindedHandleWindowError: (event: ErrorEvent) => void;

  // used for deduplicating error messages
  private lastError = "";

  private errorTimer: ReturnType<typeof setTimeout> = setTimeout(() => null);

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
    this.bindedHandleWindowError = this.handleWindowError.bind(this);
  }

  componentDidMount() {
    window.addEventListener("error", this.bindedHandleWindowError, { capture: true });
  }

  componentDidCatch(error: Error) {
    console.error(error);
    const serializedError = serializeError(error);
    this.reportError(serializedError);
    this.setState({ hasError: true });
  }

  componentWillUnmount() {
    window.removeEventListener("error", this.bindedHandleWindowError);
  }

  handleWindowError(event: ErrorEvent) {
    const { error } = event;
    if (!error) return;

    console.error(error);

    const serializedError = serializeError(error);
    this.reportError(serializedError);
  }

  reportError(errorStr: string) {
    // some errors are thrown multiple times by React and we want to report those only once
    // we compare only the first three lines, because the stack trace might be different when the error goes through componentDidCatch()
    if (firstThreeLines(errorStr) === firstThreeLines(this.lastError)) return;

    clearTimeout(this.errorTimer);
    this.props.onError(errorStr);
    this.lastError = errorStr;
    this.errorTimer = setTimeout(() => {
      console.log(this.lastError);
      this.lastError = "";
    }, 3000);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          description="If you can’t get it to work, please reach out to your contact at Relatable. Sorry
            for the inconvenience 🙏"
        />
      );
    }

    return this.props.children;
  }
}
