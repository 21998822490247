import { useMemo, useState } from "react";

import styled from "@emotion/styled";
import { CircularProgress, debounce } from "@mui/material";
import { IconButton } from "@relatable/ui/IconButton";
import { palette } from "@relatable/ui/Palette";

import { Add, Close } from "@mui/icons-material";
import {
  ContentMediaDocument,
  useCampaignsQuery,
  useUpsertStoryCaptionMutation
} from "modules/approval/components/ContentApproval/Stepper/Step/generated";
import { Textfield } from "modules/approval/components/atoms";

export const CotentItemCaption: React.FC<{
  captionId: number;
  mediaId: number;
  text: string;
  editable: boolean;
}> = ({ mediaId, text, captionId, editable }) => {
  const [hasCaption, setHasCaption] = useState(Boolean(text));
  const {
    data: {
      campaigns: [campaign] = []
    } = {}
  } = useCampaignsQuery();
  const [upsertStoryCaption, { loading: upsertStoryCaptionLoading }] =
    useUpsertStoryCaptionMutation({
      refetchQueries: captionId ? [] : [ContentMediaDocument]
    });

  const debouncedUpsertCaption = useMemo(
    () => debounce(upsertStoryCaption, 200),
    [upsertStoryCaption]
  );

  const renderCaptionAction = () => {
    const toggleCaption = () => {
      upsertStoryCaption({ variables: { mediaId, text: !hasCaption ? campaign.hashtags : "" } });
      setHasCaption(!hasCaption);
    };

    return (
      <span
        style={{
          color: palette.gray["60"],
          display: "inline-flex",
          alignItems: "center",
          ...(hasCaption && { fontSize: 13, marginLeft: 15 })
        }}
      >
        <ToggleCaptionButton onClick={toggleCaption} onKeyDown={toggleCaption}>
          <IconButton style={{ width: hasCaption ? 20 : 40, height: hasCaption ? 20 : 40 }}>
            {hasCaption ? <Close /> : <Add />}
          </IconButton>
          {hasCaption ? "Remove" : "Add optional caption"}
        </ToggleCaptionButton>
      </span>
    );
  };

  if (!editable) {
    return (
      <Root>
        <h4 style={{ margin: 0 }}>Caption</h4>
        <p>{text || "-"}</p>
      </Root>
    );
  }

  return (
    <Root>
      {hasCaption ? (
        <>
          <span style={{ display: "inline-flex", alignItems: "center" }}>
            <h4 style={{ margin: 0 }}>Caption *</h4>
            {renderCaptionAction()}
          </span>

          <span
            style={{
              padding: "5px 0 15px",
              display: "inline-flex",
              fontSize: 13,
              alignItems: "center"
            }}
          >
            Mandatory hashtags are prefilled{" "}
            {upsertStoryCaptionLoading && (
              <CircularProgress style={{ marginLeft: 5, color: palette.primary.red }} />
            )}
          </span>

          <Textfield
            multiline
            rows={3}
            placeholder="Add optional caption here"
            onChange={e =>
              debouncedUpsertCaption({
                variables: { mediaId, text: e.target.value },
                ...(captionId && {
                  optimisticResponse: {
                    insert_content_preapproval_caption_one: {
                      id: captionId,
                      text: e.target.value,
                      campaign_preapproval_content_media_id: mediaId,
                      campaign_preapproval_content_id: null,
                      __typename: "content_preapproval_caption"
                    }
                  }
                })
              })
            }
            value={text}
          />
        </>
      ) : (
        renderCaptionAction()
      )}
    </Root>
  );
};

const Root = styled.div`
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  text-align: initial;
  padding: 12px 0px;
`;

const ToggleCaptionButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;
ToggleCaptionButton.defaultProps = { role: "button", tabIndex: 0 };
