import type { FC } from "react";

import styled from "@emotion/styled";
import { Check } from "@mui/icons-material";
import { palette } from "@relatable/ui/Palette";
import { Link, useLocation } from "react-router-dom";

import type { UserByEncodedIdQuery } from "./generated";

const SHeader = styled.header`
  width: 100%;

  ul {
    display: flex;
    justify-content: space-around;
    padding: 0;
    list-style-type: none;
    text-align: center;

    .title {
      font-weight: 600;
      margin-bottom: 10px;
    }

    .circle {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background: ${palette.gray.black};
      margin: 10px;
    }

    a {
      flex-grow: 1;
      height: 100%;
      padding: 10px 0;
      display: flex;
      color: ${palette.gray[50]};
      li {
        margin: auto;
      }
    }
  }
`;

export const Header: FC<{ data: UserByEncodedIdQuery["agreementDataByEncodedId"] | undefined }> = ({
  data
}) => {
  const { pathname } = useLocation();
  const parts = pathname.split("/");
  parts.pop();
  const baseUrl = parts.join("/");

  const showAPI = (() => {
    // show API when loading
    if (!data) return true;
    if (data.skipAPIVerification) return false;
    return data.platform === "instagram" || data.platform === "tiktok";
  })();

  const steps = [
    { url: "start", label: "Start", approved: true },
    showAPI &&
      ({ url: "api", label: "API", approved: Boolean(data?.isSocialMediaTokenValid) } as const),
    { url: "details", label: "Details", approved: Boolean(data?.agreementUrl) },
    { url: "agreement", label: "Sign", approved: data?.isAgreementSigned },
    { url: "payment", label: "Payment", approved: data?.isAgreementSigned },
    { url: "done", label: "Done!", approved: false }
  ] as const;
  return (
    <SHeader>
      <ul>
        {steps.filter(Boolean).map((v, index, array) => {
          if (!v) return null;

          const isLast = array.length === index + 1;
          const isActive = pathname.endsWith(v.url);

          return (
            <Link key={v.url} to={`${baseUrl}/${v.url}`}>
              <li style={{ opacity: isActive ? 1 : 0.3 }}>
                <div className="circle">{v.approved && !isLast ? <Check /> : index + 1}</div>
                <div className="title">{v.label}</div>
              </li>
            </Link>
          );
        })}
      </ul>
    </SHeader>
  );
};
