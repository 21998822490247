import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsertContentItemCaptionMutationVariables = Types.Exact<{
  contentId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  text: Types.Scalars['String']['input'];
}>;


export type InsertContentItemCaptionMutation = { __typename?: 'Mutation', insert_content_preapproval_caption_one?: { __typename?: 'content_preapproval_caption', id: number, text?: string | null, campaign_preapproval_content_media_id?: number | null, campaign_preapproval_content_id?: number | null } | null };


export const InsertContentItemCaptionDocument = gql`
    mutation insertContentItemCaption($contentId: Int, $text: String!) {
  insert_content_preapproval_caption_one(
    object: {campaign_preapproval_content_id: $contentId, text: $text}
    on_conflict: {constraint: content_preapproval_caption_campaign_preapproval_content_id_key, update_columns: [text]}
  ) {
    id
    text
    campaign_preapproval_content_media_id
    campaign_preapproval_content_id
  }
}
    `;
export type InsertContentItemCaptionMutationFn = Apollo.MutationFunction<InsertContentItemCaptionMutation, InsertContentItemCaptionMutationVariables>;

/**
 * __useInsertContentItemCaptionMutation__
 *
 * To run a mutation, you first call `useInsertContentItemCaptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContentItemCaptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContentItemCaptionMutation, { data, loading, error }] = useInsertContentItemCaptionMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useInsertContentItemCaptionMutation(baseOptions?: Apollo.MutationHookOptions<InsertContentItemCaptionMutation, InsertContentItemCaptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertContentItemCaptionMutation, InsertContentItemCaptionMutationVariables>(InsertContentItemCaptionDocument, options);
      }
export type InsertContentItemCaptionMutationHookResult = ReturnType<typeof useInsertContentItemCaptionMutation>;
export type InsertContentItemCaptionMutationResult = Apollo.MutationResult<InsertContentItemCaptionMutation>;
export type InsertContentItemCaptionMutationOptions = Apollo.BaseMutationOptions<InsertContentItemCaptionMutation, InsertContentItemCaptionMutationVariables>;