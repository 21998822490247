import { useEffect, useState } from "react";

import styled from "@emotion/styled";
import {
  FormControl,
  Input,
  type InputLabelProps,
  type InputProps,
  FormHelperText as MUIFormHelperText
} from "@mui/material";
import { palette } from "@relatable/ui/Palette";

import { InputLabel } from "./InputLabel";

type TextfieldProps = {
  className?: string;
  errorMessage?: string;
  helperText?: string;
  label?: string;
  max?: number | string;
  min?: number | string;
  multiline?: boolean;
  readonly?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
  shrink?: InputLabelProps["shrink"];
} & Pick<
  InputProps,
  | "id"
  | "name"
  | "fullWidth"
  | "type"
  | "placeholder"
  | "rows"
  | "onChange"
  | "onKeyUp"
  | "onFocus"
  | "onBlur"
  | "minRows"
  | "maxRows"
  | "value"
  | "disabled"
  | "inputRef"
>;

export const Textfield: React.FC<TextfieldProps> = ({
  disabled = false,
  fullWidth = true,
  helperText,
  errorMessage,
  id,
  label,
  name,
  max,
  min,
  multiline = false,
  onChange,
  placeholder = "",
  readonly = false,
  required = false,
  shrink = true,
  style,
  type = "text",
  value = "",
  className = "",
  ...rest
}) => {
  const inputProps = {
    ...(rest.onKeyUp && { onKeyUp: rest.onKeyUp }),
    ...((type === "number" || type === "date") && { min, max })
  };

  const [internalValue, setInternalValue] = useState(value ?? "");

  useEffect(() => {
    setInternalValue(value ?? "");
  }, [value]);

  return (
    <Root
      className={className}
      fullWidth={fullWidth}
      data-testid={`TextField-${id}`}
      {...(style && { style })}
    >
      {label && (
        <InputLabel htmlFor={id} shrink={shrink}>
          {label}
          {required ? "*" : ""}
        </InputLabel>
      )}
      <StyledInput
        className={className}
        disabled={disabled}
        readOnly={readonly}
        id={id}
        inputProps={inputProps}
        name={name}
        onChange={e => {
          e.persist();
          setInternalValue(e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        placeholder={placeholder}
        required={required}
        type={type}
        value={internalValue}
        {...(multiline && { multiline, maxRows: 5 })}
        {...rest}
      />
      {(helperText || errorMessage) && helperText ? (
        <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
      ) : (
        <ErrorHelperText id={`${id}-error-message`}>{errorMessage}</ErrorHelperText>
      )}
    </Root>
  );
};

const Root = styled(FormControl)``;

const StyledInput = styled(Input)<{
  readOnly?: boolean;
}>`
  && {
    line-height: 1.5em;
    background-color: ${palette.gray.whiteOff};
    border: 1px solid ${palette.gray[40]};
    border-radius: 4px;
    padding: 8px;
    color: ${palette.gray.black};

    &.transparent {
      background-color: inherit;
      border: 0px;
    }

    &.Mui-focused {
      border: 1px solid ${palette.gray[80]};
      &.transparent {
        border: 0px;
      }
    }
  }

  .MuiInputBase-input {
    ${({ readOnly }) => readOnly && "cursor: not-allowed;"}
  }
`;
StyledInput.defaultProps = { disableUnderline: true };

const FormHelperText = styled(MUIFormHelperText)`
  && {
    position: absolute;
    bottom: -16px;
  }
`;

const ErrorHelperText = styled(FormHelperText)`
  && {
    color: red;
  }
`;
