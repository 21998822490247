import styled from "@emotion/styled";

interface ErrorProps {
  message?: string;
}

export const ErrorComponent: React.FC<ErrorProps> = ({
  message = "Something went wrong.. Please retry"
}) => (
  <Root>
    <h1>😕</h1>
    <p>{message}</p>
  </Root>
);

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
