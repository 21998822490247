import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useEncodedUser } from "./useEncodedUser";

export const useRedirector = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const { hash, user, campaignStub } = useEncodedUser();
  const hasProfile = Boolean(user?.profile);

  useEffect(() => {
    if (!user?.platform) return;

    const basePath = `/sign/${campaignStub}/${hash}/`;

    const showAPI = user.platform === "instagram" || user.platform === "tiktok";
    const paidOutsideGigaPay =
      user.paymentStatus === "CUSTOM_PAYMENT" || user.paymentStatus === "CUSTOM_PAYMENT_PAID";

    switch (pathname.replace(basePath, "")) {
      case "api":
        if (!showAPI || user.skipAPIVerification) {
          navigate(`${basePath}details`, { replace: true });
          return;
        }
        setIsLoaded(true);
        break;

      case "details":
        if (!user.skipAPIVerification && showAPI && !user.isSocialMediaTokenValid) {
          navigate(`${basePath}api`, { replace: true });
          return;
        }
        setIsLoaded(true);
        break;

      case "agreement":
        if (!user?.agreementUrl && !user.isAgreementSigned) {
          navigate(pathname.replace("/agreement", "/details"), { replace: true });
          return;
        }
        if (!hasProfile) {
          navigate(pathname.replace("/agreement", "/details"), { replace: true });
          return;
        }
        setIsLoaded(true);
        break;

      case "payment":
        if (!user.isAgreementSigned) {
          navigate(pathname.replace("/payment", "/agreement"), { replace: true });
          return;
        }
        if (!hasProfile) {
          navigate(pathname.replace("/payment", "/details"), { replace: true });
          return;
        }
        if (paidOutsideGigaPay) {
          navigate(pathname.replace("/payment", "/done"), { replace: true });
        }
        setIsLoaded(true);
        break;

      case "done":
        if (!user.isAgreementSigned) {
          navigate(`${basePath}agreement`, { replace: true });
          return;
        }
        if (!user.gigapayId && paidOutsideGigaPay) {
          navigate(`${basePath}payment`, { replace: true });
          return;
        }
        setIsLoaded(true);
        break;

      default:
        setIsLoaded(true);
    }

    if (!showAPI || user.skipAPIVerification) {
      navigate(pathname.replace("/api", "/details"), { replace: true });
    }
  }, [
    user?.platform,
    user?.skipAPIVerification,
    user?.isAgreementSigned,
    user?.isSocialMediaTokenValid,
    user?.agreementUrl,
    user?.gigapayId,
    user?.paymentStatus,
    hasProfile,
    navigate,
    pathname,
    hash,
    campaignStub
  ]);

  return { isLoaded };
};
