import { type FC, useMemo, useState } from "react";

import { debounce } from "@mui/material";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { creatorEditing, normalizeState } from "@relatable/helpers/index";
import { Loader } from "@relatable/ui/Loader";
import { useParams } from "react-router-dom";

import { splitTagsBySpace } from "modules/approval/lib/sponsorshipTags";

import {
  type CampaignsQueryHookResult,
  ContentStatusDocument,
  useCampaignsQuery,
  useContentStatusQuery
} from "../generated";
import { Caption } from "./Caption";
import { useInsertContentItemCaptionMutation } from "./generated";

const CaptionContainerContent: FC<{
  campaign: NonNullable<CampaignsQueryHookResult["data"]>["campaigns"][number];
}> = ({ campaign }) => {
  const { settingId } = useParams<{ settingId: string }>();
  const [caption, setCaption] = useState<null | string>(null);

  const [insertCaption, { loading: isUpdatingCaption }] = useInsertContentItemCaptionMutation({
    refetchQueries: [ContentStatusDocument]
  });

  const {
    data: { settings } = {}
  } = useContentStatusQuery({
    variables: { settingId: Number(settingId) },
    onCompleted: ({ settings: s }) => {
      const text = s?.contents[0]?.content_preapproval_caption?.text?.trim();
      const fallbackCaption = text || campaign.hashtags?.trim();
      if (fallbackCaption) {
        setCaption(fallbackCaption);
      }
      if (!text && fallbackCaption) {
        insertCaption({
          variables: { contentId, text: fallbackCaption }
        });
      }
    }
  });

  const firstContent = settings?.contents?.[0];

  const { content_preapproval_caption, id: contentId } = firstContent ?? {};

  const { caption_state } = content_preapproval_caption || {};

  const requiredTags = splitTagsBySpace(campaign?.hashtags ?? "");
  const captionContainsAllTags = requiredTags.every(
    e => caption?.toLowerCase().indexOf(e.toLowerCase()) !== -1 && caption !== campaign?.hashtags
  );
  const isStepValid = caption && captionContainsAllTags;

  const isCaptionEditable =
    !isStepValid ||
    (caption_state ? creatorEditing(caption_state as CONTENT_APPROVAL_STATE) : true);

  const debouncedInsertCaption = useMemo(
    () =>
      debounce((t: string) => {
        insertCaption({
          variables: { contentId, text: t }
        });
      }, 1000),
    [contentId, insertCaption]
  );
  return (
    <Caption
      captionState={normalizeState(caption_state)}
      caption={caption || ""}
      disabled={!isCaptionEditable}
      isLoading={isUpdatingCaption}
      onInputChange={e => {
        const c = e.target.value;
        setCaption(c);
        debouncedInsertCaption(c);
      }}
    />
  );
};

export const CaptionContainer: React.FC = () => {
  const { data } = useCampaignsQuery();
  const campaign = data?.campaigns[0];

  if (!campaign) return <Loader />;
  return <CaptionContainerContent campaign={campaign} />;
};
