import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Analytics, BusinessCenter, RequestQuote } from "@mui/icons-material";
import { Footer } from "components/Layout/Footer";
import { Header } from "components/Layout/Header";

export const StartPage: React.FC = () => {
  const { userHash } = useParams<{ userHash: string }>();

  const navigate = useNavigate();

  const storedUserHash = window.sessionStorage.getItem("relatable_userHash");
  if (storedUserHash) {
    navigate(`/overview/${storedUserHash}`);
    return null;
  }

  return (
    <>
      <Header title="Sponsorship analytics" />
      <Heading>Work better with brands</Heading>

      <AdvantageContainer>
        <Analytics />
        <i className="fas fa-analytics icon" />
        <h4>Tailored insights</h4>
        <span>
          Get performance insights for your Instagram account, optimized for brand sponsorship
          negotiations
        </span>
      </AdvantageContainer>

      <AdvantageContainer>
        <BusinessCenter
          style={{
            color: "#9e8a46",
            fontSize: 80,
            marginBottom: 10
          }}
        />
        <h4>Make a great impression</h4>
        <span>Pick data to share with prospective clients to get more gigs</span>
      </AdvantageContainer>

      <AdvantageContainer>
        <RequestQuote
          style={{
            color: "#9e8a46",
            fontSize: 80,
            marginBottom: 10
          }}
        />
        <h4>Fee calculation</h4>
        <span>
          Negotiate with confidence - get paid the right fee based on actual collaborations
        </span>
      </AdvantageContainer>

      <StyledLink
        className="waves-effect waves-light btn btn-social btn-facebook"
        type="submit"
        to={`/auth/facebook?state=${
          userHash ? `overview&uid=${userHash}` : "overview-with-no-hash"
        }&type=overview&referer=auth`}
      >
        <StyledButton>
          <i className="fab fa-facebook-square" />
          <span>Continue with Facebook</span>
        </StyledButton>
      </StyledLink>
      <Footer />
    </>
  );
};

const Heading = styled.h3`
  color: ${palette.secondary};
  margin: 36px 0px;
`;

const AdvantageContainer = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 36px;

  h4 {
    margin: 0px;
    margin-bottom: 2px;
  }

  span {
    text-transform: uppercase;
    font-size: 12px;
  }
`;

const StyledLink = styled(Link)`
  width: calc(100% - 30px);

  text-decoration: none;

  * {
    font-size: 16px;
  }

  i {
    margin-right: 10px;
  }

  span {
    margin: 0 auto;
  }
`;

const StyledButton = styled.button`
  display: flex;
  color: #ffffff;
  outline: none;
  font: inherit;
  background-color: #3568b8;
  text-align: center;
  padding: 10px;
  width: 100%;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;
