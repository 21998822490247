import type { FC } from "react";

import { Check } from "@mui/icons-material";
import { Facebook } from "@mui/icons-material";
import { Button } from "@relatable/ui/Button";
import { Loader } from "@relatable/ui/Loader";
import { Typography } from "@relatable/ui/Typography";
import { PlatformIcon } from "@relatable/ui/icons/PlatformIcon";
import { useLocation } from "react-router-dom";
import { useEncodedUser } from "./useEncodedUser";
import { useRedirector } from "./useRedirector";

export const ApiStep: FC = () => {
  const { pathname } = useLocation();
  const { user, campaignStub, hash } = useEncodedUser();

  const { isLoaded } = useRedirector();
  if (!isLoaded) return <Loader />;

  const showAPI = user?.platform === "instagram" || user?.platform === "tiktok";

  const handleConnect = () => {
    let url: URL;

    switch (user?.platform) {
      case "instagram": {
        url = new URL(`${process.env.GRAPH_URL}/facebook/login`);
        url.searchParams.set("encodedUserId", hash);
        url.searchParams.set("campaignStub", campaignStub);
        break;
      }

      case "tiktok": {
        url = new URL(`${process.env.GRAPH_URL}/tiktok/login`);
        url.searchParams.set("redirect_url", window.location.href);
        break;
      }

      default:
        throw new Error("Invalid user platform");
    }

    window.location.href = url.toString();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <p>API integration. Connect with us.</p>
      <p>Please accept our API integration to make it easier for you, the client and Relatable.</p>
      <p style={{ fontWeight: "bold" }}>Why?</p>
      <ul style={{ textAlign: "left", margin: "auto", maxWidth: 650 }}>
        <li>It helps to match you with the perfect brand.</li>
        <li>It automatically informs Relatable when you’ve published your content.</li>
        <li>
          Relatable receives all the statistics of your content within this collaboration, so you
          don’t have to share screenshots.
        </li>
      </ul>
      <p>
        Reach out to your production manager if you have questions regarding this, we are here to
        help you!
      </p>

      {showAPI && (
        <>
          <Button
            size="medium"
            color="info"
            disabled={user?.isSocialMediaTokenValid}
            onClick={handleConnect}
          >
            {user?.platform === "instagram" ? <Facebook /> : <PlatformIcon platform={"tiktok"} />}
            <div style={{ marginLeft: 7 }}>
              Connect to {user?.platform === "instagram" ? "Facebook" : "TikTok"}
            </div>
          </Button>

          {user?.isSocialMediaTokenValid && (
            <div
              style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}
            >
              <Typography color="green">Successfully connected</Typography>
              <Check style={{ color: "green" }} />
            </div>
          )}

          <Button
            disabled={showAPI ? !user?.isSocialMediaTokenValid : false}
            href={pathname.replace("/api", "/details")}
            style={{ marginTop: 20 }}
            size="medium"
          >
            Got it, next!
          </Button>
        </>
      )}
    </div>
  );
};
