import styled from "@emotion/styled";
import { InputLabel as MaterialInputLabel } from "@mui/material";

export const InputLabel = styled(MaterialInputLabel)<{ centered?: "true" }>`
  && {
    color: #000000 !important;
    z-index: 1;
  }

  &:hover {
    cursor: text;
  }

  &.MuiInputLabel-formControl {
    ${({ centered }) => centered && "left: 50%; transform: translate(-50%, 24px) scale(1);"};
  }

  &.MuiInputLabel-shrink {
    ${({ centered }) =>
      centered ? "left: 50%; transform: translate(-50%, -2px);" : "transform: translate(0, -3px);"};
    padding: 0px;
    font-size: 12px;
  }
`;
