import styled from "@emotion/styled";
import { MMM_DD } from "@relatable/helpers/date";
import { Avatar } from "@relatable/ui/Avatar";
import { Loader } from "@relatable/ui/Loader";
import { palette } from "@relatable/ui/Palette";

import { SmartToy } from "@mui/icons-material";
import type { IBotComment } from "../botComments/types";
import type { IDetailLog } from "./getFormattedLogs";

export const StatusChecklist: React.FC<{
  botComments: IBotComment[];
  detailLogs: IDetailLog[];
  loading: boolean;
}> = ({ loading, botComments = [], detailLogs = [] }) => {
  if (loading) {
    return <Loader />;
  }

  if (botComments.length === 0 && detailLogs.length === 0) return null;

  return (
    <Root>
      {botComments.length > 0 && (
        <MessageboxContent>
          <SmartToy style={{ width: 30, height: 30, marginRight: 8 }} />{" "}
          <CheckList>
            <BotName>Relatable Robot</BotName>
            <CommentBox className="transitions">
              {botComments
                .filter(item => item.label)
                .map((item, index) => (
                  <span key={item.label.toString()}>
                    {typeof item.label === "string" ? item.label.trim() : item.label}
                    {index !== botComments.length - 1 ? (
                      <>
                        <br />
                        <br />
                      </>
                    ) : null}
                  </span>
                ))}
            </CommentBox>
          </CheckList>
        </MessageboxContent>
      )}

      {detailLogs.map(log => (
        <DetailLog key={log.id}>
          <span style={{ marginRight: 5 }}>
            <Avatar src={log.profilePicture} size={30} />{" "}
          </span>
          <div style={{ display: "flex", flexDirection: "column", marginTop: 5, width: "100%" }}>
            <span style={{ display: "inline-flex" }}>
              <span style={{ marginRight: "auto" }}>
                <strong>{log.actionBy}</strong>&nbsp;
                <span style={{ marginRight: "auto" }}>{log.action}</span>
              </span>

              <span style={{ fontSize: 12, paddingTop: 4, marginLeft: 10 }}>
                {MMM_DD(log.timestamp)}
              </span>
            </span>
            {log.comment ? <CommentBox>{log.comment}</CommentBox> : null}
          </div>
        </DetailLog>
      ))}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 40px 20px 12px;
`;

const MessageboxContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const CheckList = styled.div`
  display: flex;
  flex-direction: column;
`;

const BotName = styled.span`
  display: inline-flex;
  align-items: center;
  color: ${palette.gray[90]};
  font-weight: 700;
`;

const DetailLog = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const CommentBox = styled.div`
  white-space: pre-wrap;
  background: ${palette.gray[10]};
  border-radius: 8px;
  padding: 8px 12px;

  > span {
    animation: fadeIn ease-in 0.5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`;
