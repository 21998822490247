import { getContentAggregateState, normalizeState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE, CONTENT_TYPES } from "@relatable/helpers/constants";
import { MMM_DD } from "@relatable/helpers/date";
import { useParams } from "react-router-dom";

import { PLATFORMS } from "modules/approval/lib/constants";

import { getTypeLabel } from "../utils";
import { getBotCommentsForActiveMedia } from "./Step/botComments/activeMedia";
import { getBotCommentsForCaption } from "./Step/botComments/caption";
import { getBotCommentsForMedia } from "./Step/botComments/media";
import type { IBotComment } from "./Step/botComments/types";
import { useCampaignsQuery, useContentMediaQuery, useContentStatusQuery } from "./Step/generated";
import type { CONTENT_APPROVAL_STEP } from "./StepperContainer";

export const useStepperStatus = ({
  activeStep,
  slideIndex
}: {
  activeStep: CONTENT_APPROVAL_STEP;
  slideIndex?: number;
}): { canProceed: boolean; botComments: IBotComment[] } => {
  const {
    data: { campaigns } = {}
  } = useCampaignsQuery();
  const { settingId } = useParams<{ settingId: string }>();

  const {
    data: { settings } = {}
  } = useContentStatusQuery({
    variables: { settingId: Number(settingId) }
  });

  const contentId = settings?.contents?.[0]?.id;
  const {
    data: { medias } = {}
  } = useContentMediaQuery({
    variables: { contentId: contentId ?? 0 },
    skip: !contentId
  });

  const campaign = campaigns?.[0];
  if (!settings || !medias || !campaign) {
    return { canProceed: false, botComments: [] };
  }

  const {
    contents: [contentStatus] = [],
    type,
    number_of_posts: requiredNumberOfPosts
  } = settings;

  const state = (() => {
    const itemStates: (string | null | undefined)[] = [];
    if (contentStatus.content_preapproval_caption) {
      itemStates.push(contentStatus.content_preapproval_caption.caption_state);
    }
    if (contentStatus.campaign_preapproval_content_medias.length > 0) {
      itemStates.push(...contentStatus.campaign_preapproval_content_medias.map(m => m.media_state));
    }

    return getContentAggregateState(itemStates);
  })();

  const singularTypeLabel = getTypeLabel({
    type,
    requiredNumberOfPosts,
    shouldReturnPluralForm: false
  });

  const hasCaption =
    settings.type !== CONTENT_TYPES.STORY.value && campaign?.platform !== PLATFORMS.YOUTUBE;

  const activeMedia = slideIndex !== undefined ? medias[slideIndex] : null;
  const activeMediaState = normalizeState(activeMedia?.state);

  const botComments = [] as IBotComment[];

  const { publish_date_min, publish_date_max } = settings;

  const minPublishDate = MMM_DD(publish_date_min);
  const maxPublishDate = MMM_DD(publish_date_max);

  if (activeStep === "upload") {
    if (activeMedia) {
      botComments.push(
        ...getBotCommentsForActiveMedia({
          activeMediaState,
          typeLabel: singularTypeLabel,
          contentState: state
        })
      );
    }

    botComments.push(
      ...getBotCommentsForMedia({
        state,
        currentNumberOfPosts: medias.length,
        requiredNumberOfPosts,
        hasCaption,
        type
      })
    );
  } else if (activeStep === "caption") {
    botComments.push(
      ...getBotCommentsForCaption({
        caption: contentStatus?.content_preapproval_caption?.text || "",
        hashtags: campaign?.hashtags ?? ""
      })
    );
  }

  if (state === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING) {
    const date =
      minPublishDate === maxPublishDate ? minPublishDate : `${minPublishDate} - ${maxPublishDate}`;

    botComments.push({
      label: `You're all set to post. Your post date is ${date}`,
      isError: false
    });
  }

  return {
    canProceed:
      state !== CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING && botComments.every(c => !c.isError),
    botComments
  };
};
