import type { FC } from "react";

export const Logo: FC = () => (
  <svg
    style={{ marginTop: -20 }}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="124"
    height="124"
    viewBox="0 0 1024 1024"
  >
    <title>GigaPay logo</title>
    <path
      fill="#021c47"
      d="M983.039 440.704l-41.728 134.963-31.027-134.963h-42.854l36.813 136.038c1.396 4.997 5.908 8.602 11.262 8.602 0.001 0 0.001 0 0.002 0h22.784l-1.28 4.199c-4.308 13.632-16.84 23.343-31.641 23.347h-24.73v34.816h21.248c0.043 0 0.094 0 0.144 0 30.542 0 56.539-19.464 66.262-46.664l0.153-0.491 55.552-159.846z"
    />
    <path fill="#3680ff" d="M191.283 440.243h41.523v144.691h-41.523v-144.691z" />
    <path
      fill="#3680ff"
      d="M353.28 456.064c-0.819-1.792-9.574-18.842-41.37-19.251-43.213-0.563-55.091 33.587-55.091 55.706v51.2c0 0.061-0.001 0.133-0.001 0.205 0 22.691 18.337 41.099 41.001 41.215h14.501c0.005 0 0.012 0 0.018 0 6.73 0 12.814-2.763 17.182-7.215l0.004-0.004 22.835-23.91-28.774 0.307c-0.015 0-0.033 0-0.051 0-13.771 0-24.934-11.163-24.934-24.934 0-0.018 0-0.036 0-0.054v0.003-36.71c0-0.015 0-0.033 0-0.051 0-13.771 11.163-24.934 24.934-24.934 0.018 0 0.036 0 0.054 0h7.729c11.622 0 21.043 9.421 21.043 21.043v0 104.755c-0.087 11.538-9.446 20.861-20.989 20.89h-65.641v33.382h73.677c0.076 0 0.166 0.001 0.256 0.001 30.143 0 54.579-24.436 54.579-54.579 0 0 0-0.001 0-0.001v0-152.422h-40.96z"
    />
    <path
      fill="#3680ff"
      d="M496.896 440.704h-71.168v31.795h59.29c0.092-0.002 0.2-0.003 0.309-0.003 9.925 0 17.971 8.046 17.971 17.971 0 0.091-0.001 0.182-0.002 0.273v-0.014 10.65c-12.273-3.255-26.363-5.125-40.89-5.125-0.457 0-0.913 0.002-1.369 0.006l0.070-0.001c-31.181 1.024-49.92 20.173-49.92 42.547v5.632c0 22.374 13.824 40.96 40.090 40.96h19.763c4.765-0.207 9.022-2.223 12.133-5.374l20.482-20.789v26.317h40.499v-96.819c0.001-0.092 0.001-0.201 0.001-0.309 0-26.192-21.103-47.456-47.234-47.716h-0.024zM503.91 558.822h-33.382c-0.187 0.007-0.406 0.011-0.627 0.011-10.095 0-18.278-8.184-18.278-18.278 0-0.238 0.004-0.475 0.014-0.71l-0.001 0.034c0-10.434 8.459-18.893 18.893-18.893v0h25.293c4.468 0 8.090 3.622 8.090 8.090v0z"
    />
    <path
      fill="#3680ff"
      d="M81.562 510.080h46.080v43.11h-48.281c-21.010 0-38.042-17.032-38.042-38.042v0-58.931c0.029-16.746 13.611-30.31 30.362-30.31 0 0 0 0 0 0h81.459v-35.84h-84.378c0 0 0 0 0 0-37.976 0-68.762 30.786-68.762 68.762 0 0.036 0 0.072 0 0.108v-0.006 54.528c0 39.588 32.092 71.68 71.68 71.68v0h15.36c0 0 0.001 0 0.001 0 6.969 0 13.264-2.882 17.759-7.52l0.006-0.006 23.194-24.32v31.846h37.786v-107.52h-84.224z"
    />
    <path fill="#3680ff" d="M191.283 375.219h41.523v41.523h-41.523v-41.523z" />
    <path
      fill="#021c47"
      d="M810.803 440.704h-71.219v31.795h59.136c0.092-0.002 0.2-0.003 0.309-0.003 9.925 0 17.971 8.046 17.971 17.971 0 0.091-0.001 0.182-0.002 0.273v-0.014 10.65c-12.26-3.256-26.335-5.126-40.847-5.126-0.472 0-0.943 0.002-1.414 0.006l0.072-0.001c-31.181 1.024-49.92 20.173-49.92 42.547v5.632c0 22.374 13.824 40.96 40.090 40.96h19.507c4.915-0.096 9.336-2.133 12.543-5.374l0.002-0.002 20.736-20.787v26.317h40.499v-96.819c0.001-0.091 0.001-0.2 0.001-0.308 0-26.265-21.219-47.573-47.449-47.718h-0.014zM817.766 558.822h-33.382c-0.187 0.007-0.406 0.011-0.627 0.011-10.095 0-18.278-8.184-18.278-18.278 0-0.238 0.004-0.475 0.014-0.71l-0.001 0.034c0-10.434 8.459-18.893 18.893-18.893v0h25.293c4.468 0 8.090 3.622 8.090 8.090v0z"
    />
    <path
      fill="#021c47"
      d="M668.928 440.704h-103.782v208.077h40.96v-176.281h43.622c0 0 0 0 0 0 6.476 0 11.725 5.249 11.725 11.725 0 0.018 0 0.036 0 0.054v-0.003 58.266c0.001 0.046 0.001 0.1 0.001 0.154 0 6.476-5.249 11.725-11.725 11.725-0.018 0-0.037 0-0.055 0h-43.005l26.317 26.317c2.781 2.784 6.624 4.507 10.869 4.507 0.067 0 0.134-0.001 0.2-0.001h28.764c18.162-0.171 32.846-14.819 33.075-32.951v-0.022l0.665-73.574c0.002-0.137 0.003-0.299 0.003-0.461 0-20.727-16.803-37.529-37.529-37.529-0.037 0-0.074 0-0.111 0h0.006z"
    />
  </svg>
);
