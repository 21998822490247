import { GqlProvider, getGqlClient } from "@relatable/gql";
import { useReportFeErrorMutation } from "@relatable/gql/generated";
import { ErrorBoundary } from "@relatable/ui/ErrorBoundary";
import { Loader } from "@relatable/ui/Loader";
import {
  type FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { useState } from "react";

export const AuthContext = createContext({
  login(v: { campaignUserHash?: string; userHash?: string; campaignStub?: string }) {},
  token: ""
});

const doLogin = async ({
  campaignUserHash,
  userHash,
  campaignStub
}: {
  campaignUserHash?: string;
  userHash?: string;
  campaignStub?: string;
}) => {
  try {
    const url = new URL(`${process.env.GRAPH_URL}/auth/creator/login`);
    if (campaignUserHash) {
      url.searchParams.set("encodedCampaignUserId", campaignUserHash);
    } else if (userHash) {
      url.searchParams.set("encodedUserId", userHash);
    }

    url.searchParams.set("campaignStub", campaignStub ?? "");

    const results = await fetch(url, {
      method: "POST",
      credentials: "include"
    }).then(x => x.json());

    if (!results.token) return false;
    localStorage.setItem("authToken", results.token);
    return true;
  } catch (err) {
    console.error("Login failed", err);
    return false;
  }
};

let lastLoginCallTime = 0;
let loginPromise: null | Promise<boolean> = null;
const doLoginThrottled = async (params: Parameters<typeof doLogin>[0]) => {
  if (Date.now() - lastLoginCallTime < 1000) return loginPromise;
  lastLoginCallTime = Date.now();
  loginPromise = doLogin(params);
  return loginPromise;
};

const useCreatorAuth = ({ onTokenChange }: { onTokenChange(v: string): void }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(
    lastLoginCallTime === 0 ? null : true
  );

  const handleLogin = useCallback(
    ({ campaignUserHash, userHash, campaignStub }) => {
      doLoginThrottled({ campaignUserHash, userHash, campaignStub }).then(isSuccess => {
        const token = localStorage.getItem("authToken");
        if (!token) throw new Error("Cannot find token");
        onTokenChange(token);
        setIsLoggedIn(isSuccess);
      });
    },
    [onTokenChange]
  );

  return { isLoggedIn, handleLogin };
};

const ErrorBoundaryProvider: FC<PropsWithChildren> = ({ children }) => {
  const [mutate] = useReportFeErrorMutation();

  const errorHandler = useCallback(
    (error: string) => {
      mutate({
        variables: { url: window.location.href, message: error }
      });
    },
    [mutate]
  );
  return <ErrorBoundary onError={errorHandler}>{children}</ErrorBoundary>;
};

export const AuthContextProvider: FC<
  PropsWithChildren & {
    campaignStub: string | undefined;
    campaignUserHash: string | undefined;
    userHash: string | undefined;
  }
> = ({ children, campaignStub, campaignUserHash, userHash }) => {
  const [authToken, setAuthToken] = useState("");

  const { isLoggedIn, handleLogin } = useCreatorAuth({ onTokenChange: setAuthToken });

  const authContext = useMemo(
    () => ({ login: handleLogin, token: authToken }),
    [authToken, handleLogin]
  );

  const gqlClient = useMemo(
    () =>
      getGqlClient({
        headers: {
          "origin-type": "creator",
          Authorization: `Bearer ${authToken}`
        }
      }),
    [authToken]
  );

  useEffect(() => {
    handleLogin({ campaignStub, campaignUserHash, userHash });
  }, [campaignStub, campaignUserHash, userHash, handleLogin]);

  if (!isLoggedIn) return <Loader />;

  return (
    <AuthContext.Provider value={authContext}>
      <GqlProvider client={gqlClient}>
        <ErrorBoundaryProvider>{children}</ErrorBoundaryProvider>
      </GqlProvider>
    </AuthContext.Provider>
  );
};
