import { useState } from "react";

import styled from "@emotion/styled";
import { Check } from "@mui/icons-material";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { Footer } from "components/Layout/Footer";
import { Header } from "components/Layout/Header";

interface IGAuthUser {
  ig_id?: number;
  id?: number;
  access_token?: string;
  username?: string;
  state?: string;
  instagram_business_account?: {
    id?: number;
  };
}

export const PickUser: React.FC = () => {
  const [users, _setUsers] = useState<IGAuthUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IGAuthUser | null>(null);

  const handleRadioButtonChange = event => {
    const userId = event.target.value;

    setSelectedUser(users.find(user => user.ig_id === Number(userId)) ?? null);
  };

  // this was commented to due removal of axios - such endpoint does not exist since we migrated to monorepo. The original resolver stayed in relatable-onboarding repository
  // useEffect(() => {
  //   async function fetchData() {
  //     const { data } = await axios({
  //       url: "/auth/facebook/users-to-pick-from",
  //       method: "get"
  //     });
  //     setUsers(data);
  //   }
  //   fetchData();
  // }, []);

  const userQuerystring = new URLSearchParams({
    instagram_business_account_id: String(selectedUser?.instagram_business_account?.id),
    ig_id: String(selectedUser?.ig_id),
    facebook_page_id: String(selectedUser?.id),
    access_token: String(selectedUser?.access_token),
    username: String(selectedUser?.username),
    state: String(selectedUser?.state)
  });

  return (
    <Root>
      <Header title="Confirm your Instagram account" />
      <Instructions>Select the Instagram account you want to use for this service.</Instructions>
      <StyledRadioGroup
        aria-label="igaccount"
        name="igaccount"
        onChange={handleRadioButtonChange}
        value={selectedUser?.ig_id ?? ""}
      >
        {users.length ? (
          users.map(user => (
            <StyledFormControlLabel
              key={user.ig_id}
              value={`${user.ig_id}`}
              control={<Radio checkedIcon={<Check style={{ color: "#9e8a46" }} />} />}
              label={user.username}
              checked={selectedUser?.ig_id === user.ig_id}
            />
          ))
        ) : (
          <span>No business accounts connected to the selected Facebook account</span>
        )}
      </StyledRadioGroup>
      <NextButton href={`/auth/facebook/store-token?${userQuerystring}`} disabled={!selectedUser}>
        Next
      </NextButton>
      <Footer />
    </Root>
  );
};

const Root = styled.div`
  padding-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Instructions = styled.p`
  text-transform: uppercase;
  padding: 20px 20px;
  font-size: 12px;
  color: #262626;
  text-align: center;
`;

const StyledRadioGroup = styled(RadioGroup)`
  && {
    width: calc(100% - 40px);
    align-self: flex-start;
    padding: 30px 0px 40px;
    margin: 0px 20px;
    color: #9e8a46 !important;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    border: ${({ checked, ...rest }) => {
      console.log(rest);
      return checked ? "solid 2px #9e8a46" : "";
    }};
    margin: 3px 0px;
    font-weight: 200;
  }
`;

const NextButton = styled(Button)`
  && {
    display: flex;
    outline: none;
    font: inherit;
    background-color: ${({ disabled }) => (disabled ? "#ffffff" : "#9e8a46")};
    color: ${({ disabled }) => (disabled ? "#c8c8c8" : "#ffffff")};
    border: ${({ disabled }) => (disabled ? "solid 1px #c8c8c8" : "")};
    justify-content: center;
    padding: 10px;
    width: calc(100% - 40px);
    outline: none;
    text-transform: uppercase;
    font-size: 14px;

    &:hover {
      cursor: pointer;
      background-color: ${({ disabled }) => (disabled ? "#ffffff" : "#9e8a46")};
    }
  }
`;
