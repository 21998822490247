import { creatorEditing, normalizeState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";

import { getPluralFormOf, getTypeLabel } from "modules/approval/components/ContentApproval/utils";

import type { IBotComment } from "./types";

export const getBotCommentsForMedia = ({
  state,
  currentNumberOfPosts,
  requiredNumberOfPosts,
  hasCaption,
  type
}: {
  state: CONTENT_APPROVAL_STATE;
  currentNumberOfPosts: number;
  requiredNumberOfPosts: number;
  hasCaption: boolean;
  type: string;
}): IBotComment[] => {
  const botComments: IBotComment[] = [];
  const typeLabel = getTypeLabel({ type, requiredNumberOfPosts });

  if (creatorEditing(normalizeState(state))) {
    const hasEnoughContent = currentNumberOfPosts >= requiredNumberOfPosts;
    if (hasEnoughContent) {
      botComments.push({
        isError: false,
        label: `You’ve uploaded enough to meet the minimum number of content items. Feel free to add extra ${getPluralFormOf(
          type
        )} to speed up the review process.\n\nMake sure the order of images is correct. Use the small arrows to reorder if you need them. ${
          hasCaption ? "\n\nOnce you’re good, continue to add the caption in the next step." : ""
        }
        `
      });
    } else {
      botComments.push({
        isError: true,
        label: `Please upload at least ${
          requiredNumberOfPosts - currentNumberOfPosts
        } more ${typeLabel} here.`,
        showWarning: false
      });
    }
  } else if (state === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING) {
    botComments.push({ isError: false, label: "Your content was successfully approved." });
  } else {
    botComments.push({
      isError: false,
      label: "Content successfully uploaded and it is in review."
    });
  }

  return botComments;
};
