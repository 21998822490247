import { CONTENT_APPROVAL_STATE } from "./constants";

export const normalizeState = (state: CONTENT_APPROVAL_STATE | string | null | undefined) => {
  if (!state) {
    return CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD;
  }

  if (state in CONTENT_APPROVAL_STATE) {
    return state as CONTENT_APPROVAL_STATE;
  }

  throw new Error(`Invalid content approval state ${state}`);
};

export const getContentAggregateState = (
  states: (string | null | undefined)[]
): CONTENT_APPROVAL_STATE => {
  const normalizedStates = states.map(normalizeState);

  if (normalizedStates.length === 0) return CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD;

  if (normalizedStates.some(state => state === CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD)) {
    return CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD;
  }

  if (normalizedStates.some(state => state === CONTENT_APPROVAL_STATE.MANAGER_REJECTED)) {
    return CONTENT_APPROVAL_STATE.MANAGER_REJECTED;
  }

  if (normalizedStates.some(state => state === CONTENT_APPROVAL_STATE.MANAGER_REVIEWING)) {
    return CONTENT_APPROVAL_STATE.MANAGER_REVIEWING;
  }

  if (normalizedStates.some(state => state === CONTENT_APPROVAL_STATE.CLIENT_REVIEWING)) {
    return CONTENT_APPROVAL_STATE.CLIENT_REVIEWING;
  }

  if (normalizedStates.some(state => state === CONTENT_APPROVAL_STATE.CLIENT_REJECTED)) {
    return CONTENT_APPROVAL_STATE.CLIENT_REJECTED;
  }

  if (normalizedStates.some(state => state === CONTENT_APPROVAL_STATE.CLIENT_APPROVED)) {
    return CONTENT_APPROVAL_STATE.CLIENT_APPROVED;
  }

  if (normalizedStates.every(state => state === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING)) {
    return CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING;
  }

  return CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD;
};

export const creatorEditing = (state: CONTENT_APPROVAL_STATE | string | null | undefined) => {
  const normalizedState = normalizeState(state);
  return [
    CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD,
    CONTENT_APPROVAL_STATE.MANAGER_REJECTED
  ].includes(normalizedState);
};

export const creatorDone = (state: CONTENT_APPROVAL_STATE | string | null | undefined) => {
  const normalizedState = normalizeState(state);
  return [
    CONTENT_APPROVAL_STATE.MANAGER_REVIEWING,
    CONTENT_APPROVAL_STATE.CLIENT_REVIEWING,
    CONTENT_APPROVAL_STATE.CLIENT_REJECTED,
    CONTENT_APPROVAL_STATE.CLIENT_APPROVED,
    CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING
  ].includes(normalizedState);
};

export const managerReviewed = (state: CONTENT_APPROVAL_STATE | string | null | undefined) => {
  const normalizedState = normalizeState(state);
  return [
    CONTENT_APPROVAL_STATE.MANAGER_REJECTED,
    CONTENT_APPROVAL_STATE.CLIENT_REVIEWING,
    CONTENT_APPROVAL_STATE.CLIENT_REJECTED,
    CONTENT_APPROVAL_STATE.CLIENT_APPROVED,
    CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING
  ].includes(normalizedState);
};

export const managerApproved = (state: CONTENT_APPROVAL_STATE | string | null | undefined) => {
  const normalizedState = normalizeState(state);
  return [
    CONTENT_APPROVAL_STATE.CLIENT_REVIEWING,
    CONTENT_APPROVAL_STATE.CLIENT_APPROVED,
    CONTENT_APPROVAL_STATE.CLIENT_REJECTED,
    CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING
  ].includes(normalizedState);
};

export const clientReviewed = (state: CONTENT_APPROVAL_STATE | string | null | undefined) => {
  const normalizedState = normalizeState(state);
  return [
    CONTENT_APPROVAL_STATE.CLIENT_REJECTED,
    CONTENT_APPROVAL_STATE.CLIENT_APPROVED,
    CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING
  ].includes(normalizedState);
};

export const clientApproved = (state: CONTENT_APPROVAL_STATE | string | null | undefined) => {
  const normalizedState = normalizeState(state);
  return [
    CONTENT_APPROVAL_STATE.CLIENT_APPROVED,
    CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING
  ].includes(normalizedState);
};
