export function uploadWithProgress({
  url,
  body,
  headers,
  onUploadProgress = () => {},
  method = "PUT"
}: {
  url: string;
  body: File;
  headers: Record<string, string | undefined>;
  onUploadProgress?: (progress: number) => void;
  method?: string;
}): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(method ?? "POST", url, true);
    xhr.timeout = 4 * 60 * 1000;

    Object.entries(headers).forEach(([key, value]) => {
      if (value) xhr.setRequestHeader(key, value);
    });

    xhr.upload.addEventListener("progress", ({ loaded, total }: ProgressEvent) => {
      onUploadProgress(loaded / total);
    });

    xhr.addEventListener("timeout", () => {
      reject(new Error("File Upload: Timeout error"));
    });

    xhr.addEventListener("error", err => {
      console.error(err);
      reject(new Error("File Upload: Network error"));
    });

    xhr.addEventListener("load", () => {
      const ok = xhr.status >= 200 && xhr.status < 300;

      if (!ok) {
        reject(new Error(`File Upload: HTTP Error ${xhr.status}`));
        return;
      }

      resolve(xhr.responseText);
    });

    xhr.send(body);
  });
}
