import type { useUser } from "components/hooks/useUser";

import type { ContentApprovalLogFragment } from "../generated";

export interface IDetailLog {
  action: string | JSX.Element;
  actionBy: string;
  comment?: string;
  id: number;
  profilePicture: string;
  timestamp: string;
}

export function getFormattedLogs(
  logs: ContentApprovalLogFragment[],
  {
    target,
    typeLabel,
    creator
  }: { target: "caption" | "media"; creator: ReturnType<typeof useUser>; typeLabel: string }
): IDetailLog[] {
  const logsForMedia = logs
    .filter(l => l.text !== null || l.prev_caption !== null)
    .map(log => {
      const isTargetRelatable = log.target === "relatable";

      const action: IDetailLog["action"] = `${(() => {
        if (log.text !== null) return "commented on ";
        if (log.prev_caption !== null) return "updated the ";

        console.log(log);
        throw new Error("Log is not a valid");
      })()} ${target === "media" ? typeLabel : target}`;

      return {
        id: log.id,
        actionBy: isTargetRelatable ? (creator?.username ?? "") : (log.account?.first_name ?? ""),
        action,
        comment:
          log.prev_caption !== null
            ? "Quick update. We’ve updated your caption with new text. You’ll need to use the new caption for when the post is live.\n\nPlease respond with “Ok” to ensure that you’ve seen the updated caption."
            : (log.text ?? ""),
        profilePicture: isTargetRelatable
          ? (creator?.profilePicture ?? "")
          : (log.account?.profile_picture ?? ""),
        timestamp: log.created_at || ""
      };
    });

  return logsForMedia;
}
