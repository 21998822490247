import styled from "@emotion/styled";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { Avatar } from "@relatable/ui/Avatar";
import { PlatformIcon } from "@relatable/ui/icons/PlatformIcon";
import { Link } from "react-router-dom";

interface UserCardProps {
  profilePicture: string;
  username: string;
  style?: React.CSSProperties;
  platform?: Platform_Enum;
  platformUrl?: string;
}

export const UserCard: React.FC<UserCardProps> = ({
  profilePicture,
  username,
  style = {},
  platform,
  platformUrl
}) => {
  return (
    <Root style={{ ...style }}>
      <Avatar size={40} src={profilePicture} />
      <Username>@{username}</Username>
      {/* this is used in the Overview component as
      well, and that has a different user structure, without the platform and platformUrl */}
      {platformUrl && platform ? (
        <Link to={platformUrl} target="_blank" rel="noreferrer">
          <PlatformIcon platform={platform} />
        </Link>
      ) : null}
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 10px;
`;

const Username = styled.span`
  font-weight: 700;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 6px;
`;
