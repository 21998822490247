import type { FC, PropsWithChildren } from "react";

import { Tooltip } from "@mui/material";

export const TooltipWrapper: FC<
  PropsWithChildren<{ fullWidth?: boolean; tooltipText: string }>
> = ({ children, fullWidth, tooltipText }) => {
  if (!tooltipText) return children;

  return (
    <Tooltip placement="top" title={tooltipText}>
      <span style={{ margin: fullWidth ? "auto" : 0, width: fullWidth ? "100%" : "" }}>
        {children}
      </span>
    </Tooltip>
  );
};
