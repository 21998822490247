import { type FC, useState } from "react";

import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { LogoInline } from "@relatable/ui/icons/LogoInline";
import { useParams } from "react-router-dom";

const GlobalStyles = css`
  html {
    background-color: black;
  }

  a {
    cursor: pointer;
  }
`;

const Page = styled.div`
  padding: 0 15%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;

  p {
    color: ${palette.gray[40]};
  }
  
  .nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 20px;

    .sub-nav {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 750px) {
        display: none;
      }
    }

    a {
      color: white;
      font-size: 1.1rem;
    }

    .link-box {
      text-transform: uppercase;
      border: 1px solid white;
      padding: 15px;
      border-radius: 5px;
      font-size: 1rem;
      transition: all 500ms ease;
      :hover {
        color: 1;
        background-color: white;
        color: black;
      }
    }
  }
`;

export const Resign: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const snackbar = useSnackbar();
  const { userHash } = useParams<{ userHash: string }>();

  const handleResign = async () => {
    setIsLoading(true);
    if (!process.env.GRAPH_URL) throw Error("missing GRAPH_URL");
    try {
      const res = await fetch(`${process.env.GRAPH_URL}/resign`, {
        method: "POST",
        body: JSON.stringify({ encodedId: userHash }),
        headers: {
          "Content-type": "application/json"
        }
      });
      if (!res.ok) {
        const results = await res.json();
        throw new Error(results.message);
      }
      snackbar.success("You have resigned successfully 😔");
    } catch (err) {
      console.log(err);
      if (err instanceof Error) {
        snackbar.error(err.message);
        return;
      }
      snackbar.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <Page>
        <div className="nav">
          <Button href="https://relatable.me/" style={{ marginLeft: 0 }} variant="text">
            <LogoInline color="white" style={{ width: 150 }} />
          </Button>

          <div className="sub-nav">
            <a href="https://relatable.me/#services">Services</a>
            <a style={{ margin: "0 30px" }} href="https://relatable.me/#about">
              About
            </a>
            <a href="https://relatable.me/#get-started" className="link-box">
              Get started
            </a>
          </div>
        </div>

        <div>
          <p>
            We are sorry to hear that you are not interested to collaborate with our client and us.
          </p>
          <p>
            But no worries, you will receive an invite again when we have found a better match for
            you!
          </p>
          <Button
            style={{ margin: "auto", maxWidth: 200 }}
            color="error"
            isLoading={isLoading}
            size="full"
            onClick={handleResign}
          >
            Not interested
          </Button>
        </div>
      </Page>
    </>
  );
};
