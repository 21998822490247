import { useState } from "react";

import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { useParams } from "react-router-dom";

import type { CONTENT_APPROVAL_STEP } from "../StepperContainer";
import { useStepperStatus } from "../useStepperStatus";
import { Step } from "./Step";
import { useCampaignsQuery, useContentMediaQuery, useContentStatusQuery } from "./generated";

export const StepContainer: React.FC<{
  activeStep: CONTENT_APPROVAL_STEP;
  contentState: CONTENT_APPROVAL_STATE;
  loadingAddComment: boolean;
}> = ({ activeStep, contentState, loadingAddComment }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const {
    data: { campaigns } = {}
  } = useCampaignsQuery();
  const campaign = campaigns?.[0];

  const { settingId } = useParams<{ settingId: string }>();
  const {
    data: { settings } = {}
  } = useContentStatusQuery({
    variables: { settingId: Number(settingId) }
  });
  const contentId = settings?.contents?.[0]?.id;
  const {
    data: { medias } = {}
  } = useContentMediaQuery({
    variables: { contentId: contentId ?? 0 },
    skip: !contentId
  });

  const { botComments } = useStepperStatus({
    activeStep,
    slideIndex
  });

  if (!campaign || !settings) return null;

  return (
    <Step
      activeStep={activeStep}
      briefUrl={campaign.creative_brief_url ?? ""}
      content={settings.contents[0]}
      contentState={contentState}
      medias={medias ?? []}
      onUpdateSlideIndex={setSlideIndex}
      slideIndex={slideIndex}
      botComments={botComments}
      loadingAddComment={loadingAddComment}
    />
  );
};
