import type { FC, ReactNode } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { AuthContextProvider } from "components/AuthContext";
import { useParams } from "react-router-dom";

export const ApprovalLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  const { campaignStub, campaignUserHash } = useParams<{
    campaignStub: string;
    campaignUserHash: string;
  }>();

  return (
    <Root>
      <AuthContextProvider
        userHash={undefined}
        campaignStub={campaignStub}
        campaignUserHash={campaignUserHash}
      >
        <NestedRoot>{children}</NestedRoot>
      </AuthContextProvider>
    </Root>
  );
};
const Root = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  flex-grow: 1;
  background-color: ${palette.gray[20]};
`;

const NestedRoot = styled.div`
  max-width: 400px;
  min-height: 100vh;
  margin: 0px auto;
  background: ${palette.gray.white};
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.23);
`;
