import { type FC, useState } from "react";

import styled from "@emotion/styled";

import { useUser } from "components/hooks/useUser";
import { Button, Textfield } from "modules/approval/components/atoms";

import { ChatBubble } from "@mui/icons-material";
import { ContentMediaDocument, ContentStatusDocument } from "../generated";
import { useAddContentItemCommentMutation } from "./generated";

export const ContentItemComment: FC<{ captionId: number } | { mediaId: number }> = props => {
  const user = useUser();
  const [comment, setComment] = useState("");

  const [addContentItemCaption] = useAddContentItemCommentMutation({
    onCompleted: () => setComment(""),
    refetchQueries: [ContentStatusDocument, ContentMediaDocument]
  });

  return (
    <Root>
      <Textfield
        placeholder="Add a note to Relatable"
        value={comment}
        onChange={e => setComment(e.target.value)}
        multiline
      />

      <Button
        fullWidth
        disabled={!comment}
        onClick={() =>
          addContentItemCaption({
            variables: {
              ...("captionId" in props
                ? { captionId: props.captionId }
                : { mediaId: props.mediaId }),
              campaignUserId: user?.id ?? 0,
              text: comment
            }
          })
        }
      >
        <ChatBubble style={{ marginRight: 6 }} />
        Add a note to Relatable
      </Button>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 25px;
`;
