import type { FC } from "react";

import { Loader } from "@relatable/ui/Loader";

import { useRedirector } from "./useRedirector";

export const DoneStep: FC = () => {
  const { isLoaded } = useRedirector();
  if (!isLoaded) return <Loader />;

  return (
    <div>
      <p>Thank you, you’re all set!</p>
    </div>
  );
};
