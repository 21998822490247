import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CaptionStateFragment = { __typename?: 'content_preapproval_caption', id: number, content_preapproval_logs: Array<{ __typename?: 'content_preapproval_log', id: number, state?: string | null }> };

export type MediaStateFragment = { __typename?: 'campaign_preapproval_content_media', id: number, content_preapproval_logs: Array<{ __typename?: 'content_preapproval_log', id: number, state?: string | null }> };

export type UserInfoFragment = { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null };

export type UserProfilePictureFragment = { __typename?: 'users', id: number, user_instagram?: { __typename?: 'users_instagram', id: number, profile_picture?: string | null, internal_profile_picture?: string | null } | null, user_youtube?: { __typename?: 'users_youtube', id: number, thumbnail?: string | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, profile_picture?: string | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, profile_picture?: string | null } | null };

export type IntercomFragment = { __typename?: 'users', intercom_id?: string | null, intercom_status?: string | null, intercom_updated_at?: string | null };

export type MarketsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MarketsQuery = { __typename?: 'Query', market: Array<{ __typename?: 'market', id: number, name: string }> };

export type ReportFeErrorMutationVariables = Types.Exact<{
  url: Types.Scalars['String']['input'];
  message: Types.Scalars['String']['input'];
}>;


export type ReportFeErrorMutation = { __typename?: 'Mutation', reportFEError?: void | null };

export const CaptionStateFragmentDoc = gql`
    fragment CaptionState on content_preapproval_caption {
  id
  content_preapproval_logs(
    limit: 1
    order_by: {created_at: desc}
    where: {state: {_is_null: false}}
  ) {
    id
    state
  }
}
    `;
export const MediaStateFragmentDoc = gql`
    fragment MediaState on campaign_preapproval_content_media {
  id
  content_preapproval_logs(
    limit: 1
    order_by: {created_at: desc}
    where: {state: {_is_null: false}}
  ) {
    id
    state
  }
}
    `;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on users {
  id
  user_youtube {
    id
    channel_title
    channel_id
    username
    median_views
  }
  user_tiktok {
    id
    username
    followed_by
  }
  user_instagram {
    id
    username
    followed_by
  }
  user_snapchat {
    id
    username
    subscribers
  }
}
    `;
export const UserProfilePictureFragmentDoc = gql`
    fragment UserProfilePicture on users {
  id
  user_instagram {
    id
    profile_picture
    internal_profile_picture
  }
  user_youtube {
    id
    thumbnail
  }
  user_tiktok {
    id
    profile_picture
  }
  user_snapchat {
    id
    profile_picture
  }
}
    `;
export const IntercomFragmentDoc = gql`
    fragment Intercom on users {
  intercom_id
  intercom_status
  intercom_updated_at
}
    `;
export const MarketsDocument = gql`
    query markets {
  market {
    id
    name
  }
}
    `;

/**
 * __useMarketsQuery__
 *
 * To run a query within a React component, call `useMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketsQuery(baseOptions?: Apollo.QueryHookOptions<MarketsQuery, MarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketsQuery, MarketsQueryVariables>(MarketsDocument, options);
      }
export function useMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketsQuery, MarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketsQuery, MarketsQueryVariables>(MarketsDocument, options);
        }
export function useMarketsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MarketsQuery, MarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketsQuery, MarketsQueryVariables>(MarketsDocument, options);
        }
export type MarketsQueryHookResult = ReturnType<typeof useMarketsQuery>;
export type MarketsLazyQueryHookResult = ReturnType<typeof useMarketsLazyQuery>;
export type MarketsSuspenseQueryHookResult = ReturnType<typeof useMarketsSuspenseQuery>;
export type MarketsQueryResult = Apollo.QueryResult<MarketsQuery, MarketsQueryVariables>;
export const ReportFeErrorDocument = gql`
    mutation reportFEError($url: String!, $message: String!) {
  reportFEError(url: $url, message: $message)
}
    `;
export type ReportFeErrorMutationFn = Apollo.MutationFunction<ReportFeErrorMutation, ReportFeErrorMutationVariables>;

/**
 * __useReportFeErrorMutation__
 *
 * To run a mutation, you first call `useReportFeErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportFeErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportFeErrorMutation, { data, loading, error }] = useReportFeErrorMutation({
 *   variables: {
 *      url: // value for 'url'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useReportFeErrorMutation(baseOptions?: Apollo.MutationHookOptions<ReportFeErrorMutation, ReportFeErrorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportFeErrorMutation, ReportFeErrorMutationVariables>(ReportFeErrorDocument, options);
      }
export type ReportFeErrorMutationHookResult = ReturnType<typeof useReportFeErrorMutation>;
export type ReportFeErrorMutationResult = Apollo.MutationResult<ReportFeErrorMutation>;
export type ReportFeErrorMutationOptions = Apollo.BaseMutationOptions<ReportFeErrorMutation, ReportFeErrorMutationVariables>;