import loadImage from "blueimp-load-image";

const toBlob = (canvas: HTMLCanvasElement, name: string): Promise<File> =>
  new Promise(resolve => {
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(
            new File([blob], name, {
              type: blob.type
            })
          );
        }
      },
      "image/jpeg",
      0.7
    );
  });

export const changeOrientation = async (file: File) => {
  const data = await loadImage(file, {
    orientation: true,
    crossOrigin: "Anonymous",
    canvas: true,
    meta: true
  });

  if (!("toBlob" in data.image)) return file;

  const oriented = await toBlob(data.image, file.name);
  return oriented || file;
};
