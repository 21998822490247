import styled from "@emotion/styled";
import { ChatBubble } from "@mui/icons-material";
import { normalizeState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";

import { CONTENT_APPROVAL_STATE_CREATOR_LABELS } from "modules/approval/components/ContentApproval/utils";

interface ContentStatusProps {
  contentState: CONTENT_APPROVAL_STATE | null;
  numComments: number;
}

export const ContentStatus: React.FC<ContentStatusProps> = props => {
  const { contentState, numComments } = props;
  if (contentState === null) {
    return null;
  }

  return (
    <Root $state={normalizeState(contentState)}>
      {numComments > 0 ? (
        <CommentsCounter>
          <ChatBubble style={{ margin: "0 6px 0 12px" }} />
          {numComments}
        </CommentsCounter>
      ) : null}
      <StateText>{CONTENT_APPROVAL_STATE_CREATOR_LABELS[normalizeState(contentState)]}</StateText>
    </Root>
  );
};

const Root = styled.div<{ $state: CONTENT_APPROVAL_STATE }>`
  position: relative;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100%;
  color: ${({ $state }) =>
    $state === CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD
      ? palette.gray.black
      : palette.gray.white};
  background-color: ${({ $state }) => {
    if ($state === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING) {
      return palette.primary.green;
    }
    if ($state === CONTENT_APPROVAL_STATE.MANAGER_REJECTED) {
      return palette.primary.gold;
    }
    if (
      (
        [
          CONTENT_APPROVAL_STATE.MANAGER_REVIEWING,
          CONTENT_APPROVAL_STATE.CLIENT_REVIEWING,
          CONTENT_APPROVAL_STATE.CLIENT_APPROVED,
          CONTENT_APPROVAL_STATE.CLIENT_REJECTED
        ] as (string | null)[]
      ).includes($state)
    ) {
      return palette.secondary.gold;
    }
    return "inherit";
  }};
  border-bottom: 1px solid ${palette.gray[30]};
  border-radius: 3px 3px 0px 0px;
`;

const StateText = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const CommentsCounter = styled.span`
  justify-self: "flex-start";
  position: absolute;
  left: 0;
`;
