type DateArg = Date | number | string | null | undefined;

export const isDateValid = (date: DateArg): date is Date | number | string => {
  if (!date) return false;
  return !Number.isNaN(new Date(date).getTime());
};

export const isPast = (date: DateArg) => isBefore(date, new Date());

export const isAfter = (afterDate: DateArg, beforeDate: DateArg) => {
  if (!beforeDate || !afterDate) return false;
  return Number(new Date(afterDate)) > Number(new Date(beforeDate));
};

export const isBefore = (beforeDate: DateArg, afterDate: DateArg) => {
  if (!beforeDate || !afterDate) return false;
  return Number(new Date(afterDate)) > Number(new Date(beforeDate));
};

export const isToday = (...dates: DateArg[]) =>
  dates.every(d => d && new Date(d).getDate() === new Date().getDate());

export const addDays = (date: DateArg, days: number) => {
  const newDate = date ? new Date(date) : new Date();
  return new Date(newDate.setDate(newDate.getDate() + days));
};

export const addHours = (date: string | Date, hours: number) =>
  new Date(new Date(date).getTime() + 1000 * 60 * 60 * hours);

export const addSeconds = (date: string | Date, seconds: number) =>
  new Date(new Date(date).getTime() + 1000 * seconds);

export const hoursDiff = (date: Date, date2: Date) =>
  Math.ceil(Number(date) - Number(date2)) / 1000 / 60 / 60;

export const timeFromNow = (date: DateArg) => {
  if (!date) return "";
  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 }
  ];
  const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
  const interval = intervals.find(i => i.seconds < seconds);
  if (!interval) return `${seconds} seconds ago`;
  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
};

export const daysElapsedSince = (date: DateArg) => {
  if (!date) return 0;
  const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
  return Math.floor(seconds / 60 / 60 / 24);
};

export const MMM_DD = (date: DateArg) => {
  if (!date) return "";
  const [_day, month, dayNumber, _year] = new Date(date).toDateString().split(" ");
  return `${month} ${dayNumber}`;
};

export const MMM_DD_YYYY = (date: DateArg) => {
  if (!date) return "";
  const [_day, month, dayNumber, year] = new Date(date).toDateString().split(" ");
  return `${month} ${dayNumber}, ${year}`;
};

// This is more correct than using .toISOString().split("T")[0]
// addDays('2024-03-31', 1).toISOString().split("T")[0] returns 2024-03-31
export const YYYY_MM_DD = (date: DateArg) => {
  if (!date) return "";
  const d = new Date(date);
  const parts = [
    d.getFullYear(),
    (d.getMonth() + 1).toString().padStart(2, "0"),
    d.getDate().toString().padStart(2, "0")
  ];
  return parts.join("-");
};
