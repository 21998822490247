import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContentApprovalLogFragment = { __typename?: 'content_preapproval_log', id: number, action_by_campaign_user_id?: number | null, action_by_client_contact_id?: number | null, prev_caption?: string | null, created_at: string, target?: string | null, text?: string | null, state?: string | null, account?: { __typename?: 'accounts', id: number, first_name?: string | null, profile_picture?: string | null } | null };

export type ContentMediaQueryVariables = Types.Exact<{
  contentId: Types.Scalars['Int']['input'];
}>;


export type ContentMediaQuery = { __typename?: 'Query', medias: Array<{ __typename?: 'campaign_preapproval_content_media', id: number, index?: number | null, filename?: string | null, converted_filename?: string | null, url?: string | null, thumbnail?: string | null, content_id: number, state?: string | null, logs: Array<{ __typename?: 'content_preapproval_log', id: number, action_by_campaign_user_id?: number | null, action_by_client_contact_id?: number | null, prev_caption?: string | null, created_at: string, target?: string | null, text?: string | null, state?: string | null, account?: { __typename?: 'accounts', id: number, first_name?: string | null, profile_picture?: string | null } | null }>, caption?: { __typename?: 'content_preapproval_caption', id: number, text?: string | null } | null }> };

export type ContentStatusQueryVariables = Types.Exact<{
  settingId: Types.Scalars['Int']['input'];
}>;


export type ContentStatusQuery = { __typename?: 'Query', settings?: { __typename?: 'campaign_content_settings', id: number, number_of_posts: number, type: Types.Content_Preapproval_Type_Enum, publish_date_min: string, publish_date_max: string, updated_at?: string | null, name?: string | null, contents: Array<{ __typename?: 'campaign_preapproval_content', id: number, content_preapproval_caption?: { __typename?: 'content_preapproval_caption', id: number, text?: string | null, caption_state?: string | null, logs: Array<{ __typename?: 'content_preapproval_log', id: number, action_by_campaign_user_id?: number | null, action_by_client_contact_id?: number | null, prev_caption?: string | null, created_at: string, target?: string | null, text?: string | null, state?: string | null, account?: { __typename?: 'accounts', id: number, first_name?: string | null, profile_picture?: string | null } | null }> } | null, campaign_preapproval_content_medias: Array<{ __typename?: 'campaign_preapproval_content_media', id: number, media_state?: string | null }> }> } | null };

export type CampaignsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CampaignsQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, stub: string, title: string, platform?: Types.Platform_Enum | null, language?: string | null, hashtags?: string | null, creative_brief_url?: string | null, account?: { __typename?: 'accounts', id: number, email?: string | null, first_name?: string | null, full_name?: string | null, profile_picture?: string | null } | null }> };

export type InsertEmptyContentRowMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
  settingId: Types.Scalars['Int']['input'];
  campaignUserId: Types.Scalars['Int']['input'];
}>;


export type InsertEmptyContentRowMutation = { __typename?: 'Mutation', insert_campaign_preapproval_content_one?: { __typename?: 'campaign_preapproval_content', id: number } | null };

export type MoveMediaUpMutationVariables = Types.Exact<{
  media_id: Types.Scalars['Int']['input'];
}>;


export type MoveMediaUpMutation = { __typename?: 'Mutation', moveContentMediaUp?: void | null };

export type InsertContentApprovalLogMutationVariables = Types.Exact<{
  objects: Array<Types.Content_Preapproval_Log_Insert_Input> | Types.Content_Preapproval_Log_Insert_Input;
}>;


export type InsertContentApprovalLogMutation = { __typename?: 'Mutation', insert_content_preapproval_log?: { __typename?: 'content_preapproval_log_mutation_response', returning: Array<{ __typename?: 'content_preapproval_log', id: number }> } | null };

export type UpsertStoryCaptionMutationVariables = Types.Exact<{
  mediaId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  text?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpsertStoryCaptionMutation = { __typename?: 'Mutation', insert_content_preapproval_caption_one?: { __typename?: 'content_preapproval_caption', id: number, text?: string | null, campaign_preapproval_content_media_id?: number | null, campaign_preapproval_content_id?: number | null } | null };

export type ReplaceContentApprovalContentMediaMutationVariables = Types.Exact<{
  mediaId: Types.Scalars['Int']['input'];
  filename: Types.Scalars['String']['input'];
}>;


export type ReplaceContentApprovalContentMediaMutation = { __typename?: 'Mutation', replaceMedia?: void | null };

export const ContentApprovalLogFragmentDoc = gql`
    fragment ContentApprovalLog on content_preapproval_log {
  id
  action_by_campaign_user_id
  action_by_client_contact_id
  prev_caption
  created_at
  target
  text
  state
  account {
    id
    first_name
    profile_picture
  }
}
    `;
export const ContentMediaDocument = gql`
    query contentMedia($contentId: Int!) {
  medias: campaign_preapproval_content_media(
    where: {content_id: {_eq: $contentId}, deleted: {_eq: false}}
    order_by: {index: asc}
  ) {
    id
    index
    filename
    converted_filename
    url
    thumbnail
    content_id
    state: latest_state
    logs: content_preapproval_logs(
      where: {target: {_neq: "client"}}
      order_by: {created_at: desc}
    ) {
      ...ContentApprovalLog
    }
    caption: content_preapproval_caption {
      id
      text
    }
  }
}
    ${ContentApprovalLogFragmentDoc}`;

/**
 * __useContentMediaQuery__
 *
 * To run a query within a React component, call `useContentMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentMediaQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useContentMediaQuery(baseOptions: Apollo.QueryHookOptions<ContentMediaQuery, ContentMediaQueryVariables> & ({ variables: ContentMediaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentMediaQuery, ContentMediaQueryVariables>(ContentMediaDocument, options);
      }
export function useContentMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentMediaQuery, ContentMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentMediaQuery, ContentMediaQueryVariables>(ContentMediaDocument, options);
        }
export function useContentMediaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContentMediaQuery, ContentMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContentMediaQuery, ContentMediaQueryVariables>(ContentMediaDocument, options);
        }
export type ContentMediaQueryHookResult = ReturnType<typeof useContentMediaQuery>;
export type ContentMediaLazyQueryHookResult = ReturnType<typeof useContentMediaLazyQuery>;
export type ContentMediaSuspenseQueryHookResult = ReturnType<typeof useContentMediaSuspenseQuery>;
export type ContentMediaQueryResult = Apollo.QueryResult<ContentMediaQuery, ContentMediaQueryVariables>;
export const ContentStatusDocument = gql`
    query contentStatus($settingId: Int!) {
  settings: campaign_content_settings_by_pk(id: $settingId) {
    id
    number_of_posts
    type
    publish_date_min
    publish_date_max
    updated_at
    name
    contents: campaign_preapproval_contents(limit: 1) {
      id
      content_preapproval_caption {
        id
        text
        caption_state: latest_state
        logs: content_preapproval_logs(
          where: {target: {_neq: "client"}}
          order_by: {created_at: desc}
        ) {
          ...ContentApprovalLog
        }
      }
      campaign_preapproval_content_medias(where: {deleted: {_eq: false}}) {
        id
        media_state: latest_state
      }
    }
  }
}
    ${ContentApprovalLogFragmentDoc}`;

/**
 * __useContentStatusQuery__
 *
 * To run a query within a React component, call `useContentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentStatusQuery({
 *   variables: {
 *      settingId: // value for 'settingId'
 *   },
 * });
 */
export function useContentStatusQuery(baseOptions: Apollo.QueryHookOptions<ContentStatusQuery, ContentStatusQueryVariables> & ({ variables: ContentStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentStatusQuery, ContentStatusQueryVariables>(ContentStatusDocument, options);
      }
export function useContentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentStatusQuery, ContentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentStatusQuery, ContentStatusQueryVariables>(ContentStatusDocument, options);
        }
export function useContentStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContentStatusQuery, ContentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContentStatusQuery, ContentStatusQueryVariables>(ContentStatusDocument, options);
        }
export type ContentStatusQueryHookResult = ReturnType<typeof useContentStatusQuery>;
export type ContentStatusLazyQueryHookResult = ReturnType<typeof useContentStatusLazyQuery>;
export type ContentStatusSuspenseQueryHookResult = ReturnType<typeof useContentStatusSuspenseQuery>;
export type ContentStatusQueryResult = Apollo.QueryResult<ContentStatusQuery, ContentStatusQueryVariables>;
export const CampaignsDocument = gql`
    query campaigns {
  campaigns: campaign {
    id
    stub
    title
    platform
    language
    hashtags
    creative_brief_url
    account {
      id
      email
      first_name
      full_name
      profile_picture
    }
  }
}
    `;

/**
 * __useCampaignsQuery__
 *
 * To run a query within a React component, call `useCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
      }
export function useCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
        }
export function useCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
        }
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsSuspenseQueryHookResult = ReturnType<typeof useCampaignsSuspenseQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<CampaignsQuery, CampaignsQueryVariables>;
export const InsertEmptyContentRowDocument = gql`
    mutation insertEmptyContentRow($campaignId: Int!, $settingId: Int!, $campaignUserId: Int!) {
  insert_campaign_preapproval_content_one(
    object: {setting_id: $settingId, campaign_id: $campaignId, user_id: $campaignUserId}
  ) {
    id
  }
}
    `;
export type InsertEmptyContentRowMutationFn = Apollo.MutationFunction<InsertEmptyContentRowMutation, InsertEmptyContentRowMutationVariables>;

/**
 * __useInsertEmptyContentRowMutation__
 *
 * To run a mutation, you first call `useInsertEmptyContentRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmptyContentRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmptyContentRowMutation, { data, loading, error }] = useInsertEmptyContentRowMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      settingId: // value for 'settingId'
 *      campaignUserId: // value for 'campaignUserId'
 *   },
 * });
 */
export function useInsertEmptyContentRowMutation(baseOptions?: Apollo.MutationHookOptions<InsertEmptyContentRowMutation, InsertEmptyContentRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEmptyContentRowMutation, InsertEmptyContentRowMutationVariables>(InsertEmptyContentRowDocument, options);
      }
export type InsertEmptyContentRowMutationHookResult = ReturnType<typeof useInsertEmptyContentRowMutation>;
export type InsertEmptyContentRowMutationResult = Apollo.MutationResult<InsertEmptyContentRowMutation>;
export type InsertEmptyContentRowMutationOptions = Apollo.BaseMutationOptions<InsertEmptyContentRowMutation, InsertEmptyContentRowMutationVariables>;
export const MoveMediaUpDocument = gql`
    mutation moveMediaUp($media_id: Int!) {
  moveContentMediaUp(mediaId: $media_id)
}
    `;
export type MoveMediaUpMutationFn = Apollo.MutationFunction<MoveMediaUpMutation, MoveMediaUpMutationVariables>;

/**
 * __useMoveMediaUpMutation__
 *
 * To run a mutation, you first call `useMoveMediaUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMediaUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMediaUpMutation, { data, loading, error }] = useMoveMediaUpMutation({
 *   variables: {
 *      media_id: // value for 'media_id'
 *   },
 * });
 */
export function useMoveMediaUpMutation(baseOptions?: Apollo.MutationHookOptions<MoveMediaUpMutation, MoveMediaUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveMediaUpMutation, MoveMediaUpMutationVariables>(MoveMediaUpDocument, options);
      }
export type MoveMediaUpMutationHookResult = ReturnType<typeof useMoveMediaUpMutation>;
export type MoveMediaUpMutationResult = Apollo.MutationResult<MoveMediaUpMutation>;
export type MoveMediaUpMutationOptions = Apollo.BaseMutationOptions<MoveMediaUpMutation, MoveMediaUpMutationVariables>;
export const InsertContentApprovalLogDocument = gql`
    mutation insertContentApprovalLog($objects: [content_preapproval_log_insert_input!]!) {
  insert_content_preapproval_log(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertContentApprovalLogMutationFn = Apollo.MutationFunction<InsertContentApprovalLogMutation, InsertContentApprovalLogMutationVariables>;

/**
 * __useInsertContentApprovalLogMutation__
 *
 * To run a mutation, you first call `useInsertContentApprovalLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContentApprovalLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContentApprovalLogMutation, { data, loading, error }] = useInsertContentApprovalLogMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertContentApprovalLogMutation(baseOptions?: Apollo.MutationHookOptions<InsertContentApprovalLogMutation, InsertContentApprovalLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertContentApprovalLogMutation, InsertContentApprovalLogMutationVariables>(InsertContentApprovalLogDocument, options);
      }
export type InsertContentApprovalLogMutationHookResult = ReturnType<typeof useInsertContentApprovalLogMutation>;
export type InsertContentApprovalLogMutationResult = Apollo.MutationResult<InsertContentApprovalLogMutation>;
export type InsertContentApprovalLogMutationOptions = Apollo.BaseMutationOptions<InsertContentApprovalLogMutation, InsertContentApprovalLogMutationVariables>;
export const UpsertStoryCaptionDocument = gql`
    mutation upsertStoryCaption($mediaId: Int, $text: String) {
  insert_content_preapproval_caption_one(
    object: {campaign_preapproval_content_media_id: $mediaId, text: $text}
    on_conflict: {constraint: content_preapproval_caption_campaign_preapproval_content_me_key, update_columns: [text]}
  ) {
    id
    text
    campaign_preapproval_content_media_id
    campaign_preapproval_content_id
  }
}
    `;
export type UpsertStoryCaptionMutationFn = Apollo.MutationFunction<UpsertStoryCaptionMutation, UpsertStoryCaptionMutationVariables>;

/**
 * __useUpsertStoryCaptionMutation__
 *
 * To run a mutation, you first call `useUpsertStoryCaptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertStoryCaptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStoryCaptionMutation, { data, loading, error }] = useUpsertStoryCaptionMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpsertStoryCaptionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertStoryCaptionMutation, UpsertStoryCaptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertStoryCaptionMutation, UpsertStoryCaptionMutationVariables>(UpsertStoryCaptionDocument, options);
      }
export type UpsertStoryCaptionMutationHookResult = ReturnType<typeof useUpsertStoryCaptionMutation>;
export type UpsertStoryCaptionMutationResult = Apollo.MutationResult<UpsertStoryCaptionMutation>;
export type UpsertStoryCaptionMutationOptions = Apollo.BaseMutationOptions<UpsertStoryCaptionMutation, UpsertStoryCaptionMutationVariables>;
export const ReplaceContentApprovalContentMediaDocument = gql`
    mutation replaceContentApprovalContentMedia($mediaId: Int!, $filename: String!) {
  replaceMedia(mediaId: $mediaId, filename: $filename)
}
    `;
export type ReplaceContentApprovalContentMediaMutationFn = Apollo.MutationFunction<ReplaceContentApprovalContentMediaMutation, ReplaceContentApprovalContentMediaMutationVariables>;

/**
 * __useReplaceContentApprovalContentMediaMutation__
 *
 * To run a mutation, you first call `useReplaceContentApprovalContentMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceContentApprovalContentMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceContentApprovalContentMediaMutation, { data, loading, error }] = useReplaceContentApprovalContentMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useReplaceContentApprovalContentMediaMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceContentApprovalContentMediaMutation, ReplaceContentApprovalContentMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceContentApprovalContentMediaMutation, ReplaceContentApprovalContentMediaMutationVariables>(ReplaceContentApprovalContentMediaDocument, options);
      }
export type ReplaceContentApprovalContentMediaMutationHookResult = ReturnType<typeof useReplaceContentApprovalContentMediaMutation>;
export type ReplaceContentApprovalContentMediaMutationResult = Apollo.MutationResult<ReplaceContentApprovalContentMediaMutation>;
export type ReplaceContentApprovalContentMediaMutationOptions = Apollo.BaseMutationOptions<ReplaceContentApprovalContentMediaMutation, ReplaceContentApprovalContentMediaMutationVariables>;