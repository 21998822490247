import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";

export const PLATFORMS = {
  INSTAGRAM: "instagram",
  YOUTUBE: "youtube",
  TIKTOK: "tiktok",
  SNAPCHAT: "snapchat"
} as const;

export const CONTENT_APPROVAL_LABEL: Record<CONTENT_APPROVAL_STATE, string> = {
  CREATOR_INITIAL_UPLOAD: "Waiting for content",
  MANAGER_REVIEWING: "Relatable reviewing",
  MANAGER_REJECTED: "Needs updates",
  CLIENT_REVIEWING: "In review",
  CLIENT_REJECTED: "In review",
  CLIENT_APPROVED: "In review",
  READY_FOR_PUBLISHING: "Ready for publishing"
} as const;
