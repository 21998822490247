import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { useNavigate } from "react-router-dom";

import { Footer } from "components/Layout/Footer";
import { Header } from "components/Layout/Header";

export const DeleteData: React.FC = () => {
  const navigate = useNavigate();

  const handleDataDelete = () => {
    // eslint-disable-next-line no-alert
    window.alert("Data deleted");
    navigate(-1);
  };

  return (
    <Root>
      <Header title="Settings" />
      <Heading>Delete all data</Heading>
      <Text>
        By continuing, all your data will be deleted. This includes campaign information and
        performance.
      </Text>

      <ActionButton onClick={() => navigate(-1)}>Cancel</ActionButton>
      <ActionButton isWarningButton onClick={handleDataDelete}>
        Delete all my data
      </ActionButton>

      <Text>There’s no undo once you’ve pressed the button.</Text>
      <Footer />
    </Root>
  );
};

const Root = styled.div`
  padding-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h2`
  color: ${palette.gray[60]};
`;

const Text = styled.span`
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  margin: 10px 15px;
`;

const ActionButton = styled.button<{ isWarningButton?: boolean }>`
  padding: 8px 0px;
  border: solid 1px ${({ isWarningButton }) => (isWarningButton ? "#e30b5c" : "#262626")};
  color: ${({ isWarningButton }) => (isWarningButton ? "#FFFFFF" : "#262626")};
  outline: none;
  font: inherit;
  background-color: ${({ isWarningButton }) => (isWarningButton ? "#e30b5c" : "inherit")};
  font-size: 16px;
  margin: 12px 15px;
  width: calc(100% - 30px);
  text-align: center;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
`;
