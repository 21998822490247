import type { PropsWithChildren } from "react";

import styled from "@emotion/styled";
import { isInternalLink } from "@relatable/helpers";
import { Link } from "react-router-dom";

export const CustomLink: React.FC<
  PropsWithChildren<{
    className?: string;
    url?: string;
    show?: boolean;
  }>
> = ({ className, url, show = true, children }) => {
  const customLinkProps = { $show: show, className };

  if (!url) {
    return children;
  }

  return isInternalLink(url) ? (
    <StyledLink to={url} {...customLinkProps}>
      {children}
    </StyledLink>
  ) : (
    <StyledAnchorTag to={url} target="_blank" rel="noreferrer" {...customLinkProps}>
      {children}
    </StyledAnchorTag>
  );
};

const StyledAnchorTag = styled(Link, {
  shouldForwardProp: prop => prop !== "$show"
})<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? "block" : "none")};
  position: relative;
  color: inherit;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
`;

const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== "$show"
})<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? "block" : "none")};
  height: 100%;
`;
