import type { Content_Preapproval_Type_Enum } from "@relatable/gql/generated-base";
import { type CONTENT_APPROVAL_STATE, CONTENT_TYPES } from "@relatable/helpers/constants";
import { PHOTO_FILE_TYPES, VIDEO_FILE_TYPES } from "@relatable/helpers/index";

import type { ContentApprovalListQuery } from "./ContentList/generated";

const videoFileTypesWithDot = VIDEO_FILE_TYPES.map(type => `.${type}`);
const photoFileTypesWithDot = PHOTO_FILE_TYPES.map(type => `.${type}`);

export const getAcceptedFiles = (type: Content_Preapproval_Type_Enum | null) => {
  let acceptedFiles: string[] | null = null;

  switch (type) {
    case CONTENT_TYPES.CAROUSEL.value:
    case CONTENT_TYPES.PHOTO.value:
    case CONTENT_TYPES.STORY.value:
    case CONTENT_TYPES.SNAP.value:
      acceptedFiles = [...photoFileTypesWithDot, ...videoFileTypesWithDot];
      break;
    case CONTENT_TYPES.TIKTOK.value:
    case CONTENT_TYPES.VIDEO.value:
    case CONTENT_TYPES.YOUTUBE.value:
      acceptedFiles = [...videoFileTypesWithDot];
      break;
    default:
      throw new Error(`${type} is not valid`);
  }

  return acceptedFiles;
};

export const getPluralFormOf = (type: string) => {
  switch (type) {
    case CONTENT_TYPES.PHOTO.value:
    case CONTENT_TYPES.CAROUSEL.value:
      return "images";
    case CONTENT_TYPES.STORY.value:
      return "clips";
    case CONTENT_TYPES.VIDEO.value:
    case CONTENT_TYPES.TIKTOK.value:
    case CONTENT_TYPES.YOUTUBE.value:
      return "videos";
    case CONTENT_TYPES.SNAP.value:
      return "snaps";
    default:
      throw new Error(`${type} is not valid`);
  }
};

export const getTypeLabel = ({
  type,
  requiredNumberOfPosts = 0,
  shouldReturnPluralForm = true
}: {
  type: string;
  requiredNumberOfPosts?: number;
  shouldReturnPluralForm?: boolean;
}): string => {
  if (requiredNumberOfPosts > 1 && shouldReturnPluralForm) {
    return getPluralFormOf(type);
  }

  let toReturn = "";

  switch (type) {
    case CONTENT_TYPES.PHOTO.value:
    case CONTENT_TYPES.CAROUSEL.value:
      toReturn = "image";
      break;
    case CONTENT_TYPES.STORY.value:
      toReturn = "clip";
      break;
    case CONTENT_TYPES.TIKTOK.value:
    case CONTENT_TYPES.VIDEO.value:
    case CONTENT_TYPES.YOUTUBE.value:
      toReturn = "video";
      break;
    case CONTENT_TYPES.SNAP.value:
      toReturn = "snap";
      break;
    default:
      toReturn = "undefined";
  }

  return toReturn;
};

export const CONTENT_APPROVAL_STATE_CREATOR_LABELS: Record<CONTENT_APPROVAL_STATE, string> = {
  CREATOR_INITIAL_UPLOAD: "Waiting for content",
  MANAGER_REVIEWING: "Relatable reviewing",
  MANAGER_REJECTED: "Needs updates",
  CLIENT_REVIEWING: "In review",
  CLIENT_REJECTED: "In review",
  CLIENT_APPROVED: "In review",
  READY_FOR_PUBLISHING: "Approved"
};

type ContentApprovalListState =
  ContentApprovalListQuery["campaign"][number]["campaign_content_settings"][number];

export function getContentUpdatedAtTimestamps(
  campaignPreapprovalContent: ContentApprovalListState["campaign_preapproval_contents"][0]
): string[] {
  return [
    campaignPreapprovalContent?.caption?.updated_at,
    campaignPreapprovalContent?.caption?.content_preapproval_logs_aggregate?.aggregate?.max
      ?.created_at,
    (campaignPreapprovalContent?.medias ?? []).flatMap(m => [
      m.updated,
      m.content_preapproval_logs_aggregate.aggregate?.max?.created_at,
      m.content_preapproval_caption?.updated_at
    ])
  ]
    .flat()
    .filter(Boolean) as string[];
}

export function getNumberOfCommentsForContent(
  campaignPreapprovalContent: ContentApprovalListState["campaign_preapproval_contents"][0]
): number {
  return (
    (campaignPreapprovalContent?.caption?.content_preapproval_logs_aggregate.aggregate?.count ??
      0) +
    (campaignPreapprovalContent?.medias.reduce((acc, cm) => {
      return (
        acc +
        (cm.content_preapproval_caption?.content_preapproval_logs_aggregate.aggregate?.count ?? 0) +
        (cm.content_preapproval_logs_aggregate.aggregate?.count ?? 0)
      );
    }, 0) || 0)
  );
}
