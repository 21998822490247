import styled from "@emotion/styled";
import { Button as MaterialButton, type ButtonProps as MaterialButtonProps } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

type ButtonVariant = "normal" | "black";

type ButtonProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  variant?: ButtonVariant;
} & Pick<MaterialButtonProps, "type" | "fullWidth">;

export const Button = (props: ButtonProps): JSX.Element => {
  const {
    children,
    className = "",
    disabled = false,
    fullWidth = false,
    onClick,
    type = "button",
    variant = "normal"
  } = props;

  return (
    <StyledButton
      fullWidth={fullWidth}
      $variant={variant}
      className={className}
      disabled={disabled}
      type={type}
      {...(!disabled && { onClick })}
    >
      {children}
    </StyledButton>
  );
};

interface StyledButtonProps {
  $variant: ButtonVariant;
}

const StyledButton = styled(MaterialButton, {
  shouldForwardProp: prop => prop !== "$variant"
})<StyledButtonProps>`
  && {
    padding: 10px 15px;
    height: 40px;
    font-weight: bold;
    text-transform: none;
    background: ${({ $variant }) =>
      $variant === "black" ? palette.gray.black : palette.gray.whiteOff};
    color: ${({ $variant }) => ($variant === "black" ? palette.gray.white : palette.gray.black)};
    font-size: 1rem;
    border: ${({ $variant }) => ($variant === "black" ? "0px" : `1px solid ${palette.gray.black}`)};

    &.Mui-disabled {
      color: ${({ $variant }) => ($variant === "black" ? palette.gray.white : palette.gray.black)};
      opacity: 0.5;
    }

    &:hover {
      background: ${({ $variant }) => ($variant === "black" ? palette.gray[80] : palette.gray[20])};
      filter: brightness(90%);
    }
  }
`;
