import { type FC, Fragment, type ReactElement, useState } from "react";
import "react-phone-input-2/lib/style.css";

import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { CountrySelect } from "@relatable/ui/CountrySelect";
import { Divider } from "@relatable/ui/Divider";
import { Loader } from "@relatable/ui/Loader";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";

import {
  UserByEncodedIdDocument,
  useUpdateUserMutation,
  useUpdateUserProfileMutation
} from "./generated";
import { useEncodedUser } from "./useEncodedUser";
import { useRedirector } from "./useRedirector";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  gap: 10px;
  margin: auto;

  li {
    display: flex;
  }

  .special-label {
    display: block !important;
    color: ${palette.gray[60]};
    font-size: 0.8rem !important;
    background: linear-gradient(1deg, white, transparent) !important;
  }
`;

export const DetailsStep: FC = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { user } = useEncodedUser();
  if (!user) throw Error("user should be loaded in layout");

  const { profile } = user;
  const [state, setState] = useState({
    first_name: user.first_name,
    email: user.email,
    last_name: profile?.last_name,
    address_meta: profile?.address_meta,
    address_city: profile?.address_city,
    address_post_code: profile?.address_post_code,
    address_state: profile?.address_state,
    address_country: profile?.address_country,
    company_name: profile?.company_name,
    company_id: profile?.company_id,
    company_address: profile?.company_address,
    phone_number: profile?.phone_number
  });
  const [isAdult, setIsAdult] = useState(Boolean(profile));

  const [updateUserEmail, updateUserEmailOptions] = useUpdateUserMutation({
    onError: () => snackbar.error("Something went wrong 😔")
  });

  const [updateAgreementData, updateAgreementDataOptions] = useUpdateUserProfileMutation({
    onError: () => snackbar.error("Something went wrong 😔"),
    refetchQueries: [UserByEncodedIdDocument],
    awaitRefetchQueries: true
  });

  const { isLoaded } = useRedirector();
  if (!isLoaded) return <Loader />;

  const fields: {
    label: string;
    key: keyof typeof state | "breaker";
    custom?: ReactElement;
    optional?: boolean;
  }[] = [
    { label: "First name", key: "first_name" },
    { label: "Last name", key: "last_name" },
    { label: "Email", key: "email" },
    {
      label: "breaker1",
      key: "breaker",
      custom: <Divider style={{ margin: "10px 0", opacity: 0.8, width: "100%" }} />
    },

    { label: "Company name", key: "company_name", optional: true },
    { label: "Company id", key: "company_id", optional: true }
  ];

  if (user.logisticsEnabled) {
    fields.splice(3, 0, {
      label: "Phone number*",
      key: "phone_number",
      custom: (
        <PhoneInput
          country="se"
          isValid={(inputNumber, country, countries: { dialCode?: string }[]) =>
            countries.some(
              c => inputNumber.startsWith(c?.dialCode || "") || c?.dialCode?.startsWith(inputNumber)
            )
          }
          containerStyle={{}}
          inputStyle={{ width: "100%", padding: "25px 50px" }}
          buttonStyle={{ background: "transparent" }}
          inputProps={{
            id: "phoneNumber"
          }}
          specialLabel="International phone number* - start with a country prefix like +46…"
          inputClass="phoneInput"
          jumpCursorToEnd={false}
          value={state.phone_number || ""}
          onChange={v => setState({ ...state, phone_number: v })}
        />
      )
    });
    fields.splice(
      5,
      0,
      { label: "Address", key: "address_meta" },
      { label: "City", key: "address_city" },
      { label: "Post Code", key: "address_post_code" },
      {
        label: "Country",
        key: "address_country",
        custom: (
          <CountrySelect
            value={state.address_country}
            onChange={v => setState({ ...state, address_country: v || "" })}
          />
        )
      },
      { label: "State", key: "address_state" },
      {
        label: "breaker2",
        key: "breaker",
        custom: <Divider style={{ margin: "10px 0", opacity: 0.8, width: "100%" }} />
      }
    );

    fields.push({ label: "Company address", key: "company_address", optional: true });
  } else {
    fields.splice(3, 0, {
      label: "Country",
      key: "address_country",
      custom: (
        <CountrySelect
          value={state.address_country}
          onChange={v => setState({ ...state, address_country: v || "" })}
        />
      )
    });
  }

  return (
    <Root>
      <p style={{ width: "100%" }}>Now we just need a few additional pieces of information.</p>
      {fields.map(i => {
        if (i.custom) return <Fragment key={i.key + i.label}>{i.custom}</Fragment>;

        return (
          <TextInput
            color="secondary"
            required={!i.optional}
            key={i.key}
            style={{ width: "100%", background: palette.gray.white }}
            value={state[i.key] || ""}
            label={i.label}
            placeholder={i.label}
            onChange={v => setState({ ...state, [i.key]: v })}
          />
        );
      })}

      <Checkbox label="Are you over 18 years old?" checked={isAdult} onChange={setIsAdult} />

      <div style={{ width: "100%" }}>
        <Button
          style={{ marginTop: 10 }}
          size="medium"
          isLoading={updateAgreementDataOptions.loading || updateUserEmailOptions.loading}
          onClick={async () => {
            if (!isAdult) {
              snackbar.error("You must be over 18 years old to continue");
              return;
            }
            if (!state.address_country || !countriesWhiteList.includes(state.address_country)) {
              snackbar.error(
                "Unfortunately your tax residency country is not reachable by our payment provider."
              );
              return;
            }

            const mandatoryKeys = fields
              .filter(f => {
                if (f.key === "breaker") return false;
                return !f.optional;
              })
              .map(f => f.key);

            const missingKeys = mandatoryKeys
              .filter(key => !state[key])
              .map(key => key.replace("_", " "));

            if (missingKeys.length) {
              snackbar.error(`Following fields are mandatory: ${missingKeys.join(",")}`);
              return;
            }

            const { email, first_name, ...object } = state;

            await Promise.all([
              updateAgreementData({
                variables: {
                  object
                }
              }),
              (email !== user.email || first_name !== user.first_name) &&
                updateUserEmail({
                  variables: {
                    set: { email, first_name },
                    id: user.id
                  }
                })
            ]);

            navigate(pathname.replace("/details", "/agreement"));
          }}
        >
          Save, next!
        </Button>
      </div>
    </Root>
  );
};

const countriesWhiteList = [
  "Argentina",
  "Australia",
  "Austria",
  "Belgium",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Colombia",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Japan",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Mexico",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "India"
];
