import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { Overview } from "./Overview";
import { useCreatorDashboardQuery } from "./generated";

export const OverviewContainer: React.FC = () => {
  const { userHash } = useParams<{ userHash: string }>();
  const navigate = useNavigate();

  const { data, loading } = useCreatorDashboardQuery();

  const user = data?.creatorDashboard;

  if (loading) {
    return <Loader />;
  }

  if (user && (!user.hasFbAccessToken || !user.profileMatchesAccessToken)) {
    navigate(`/auth/${userHash}`);
    return null;
  }

  if (!user || !userHash) return null;

  return <Overview user={user} />;
};
