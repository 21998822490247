import type { FC } from "react";

import { LinearProgress } from "@mui/material";

export const LinearLoader: FC<{
  visible: boolean;
  color?: "primary" | "secondary";
  style?: React.CSSProperties;
}> = ({ color, visible, style }) => {
  // MUI's LinearLoader needs to be removed from DOM when not visible.
  // It causes high CPU usage even with opacity: 0
  if (!visible) return <div style={{ height: 4 }} />;

  return <LinearProgress color={color} style={{ ...style }} />;
};
