import { useEffect, useState } from "react";

import { creatorEditing, getContentAggregateState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE, CONTENT_TYPES } from "@relatable/helpers/constants";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { ErrorComponent } from "components/Layout/Error";
import { useUser } from "components/hooks/useUser";

import {
  ContentMediaDocument,
  ContentStatusDocument,
  useCampaignsQuery,
  useContentMediaQuery,
  useContentStatusQuery,
  useInsertContentApprovalLogMutation,
  useInsertEmptyContentRowMutation
} from "./Step/generated";
import { Stepper } from "./Stepper";
import { useSendSlackNotificationMutation } from "./generated";
import { useStepperStatus } from "./useStepperStatus";

export type CONTENT_APPROVAL_STEP = "upload" | "caption" | "success";

export const StepperContainer: React.FC = () => {
  const snackbar = useSnackbar();
  const { settingId, campaignStub, campaignUserHash } = useParams<{
    settingId: string;
    campaignStub: string;
    campaignUserHash: string;
  }>();
  const user = useUser();
  const [activeStep, setActiveStep] = useState<CONTENT_APPROVAL_STEP>("upload");

  const {
    data: { campaigns } = {},
    loading: getCampaignLoading,
    error: getCampaignError
  } = useCampaignsQuery();

  const {
    data: { settings } = {},
    error: getContentStatusError
  } = useContentStatusQuery({
    variables: { settingId: Number(settingId) }
  });

  const contentId = settings?.contents?.[0]?.id;
  const {
    data: { medias = [] } = {}
  } = useContentMediaQuery({
    skip: !contentId,
    variables: { contentId: contentId ?? 0 }
  });

  const { canProceed } = useStepperStatus({
    activeStep
  });

  const [sendSlackNotification] = useSendSlackNotificationMutation();

  const [insertEmptyContentRow, { called: insertEmptyContentRowCalled }] =
    useInsertEmptyContentRowMutation({
      awaitRefetchQueries: true,
      refetchQueries: [ContentStatusDocument, ContentMediaDocument]
    });

  const [insertApprovalLog, { loading: insertApprovalLogLoading }] =
    useInsertContentApprovalLogMutation({
      onCompleted: () => {
        const batchName = settings ? `(${settings.name})` : "";
        sendSlackNotification({
          variables: {
            channel: "campaigns-preapproval",
            text: `:mag_right: *${user?.username}* just sent in content for review for the *${campaigns?.[0].title}* campaign ${batchName}.`,
            campaignId: Number(campaigns?.[0].id)
          }
        });
        snackbar.success("Your content is submitted for review", { verticalAnchor: "top" });
        goBackToOverview();
      }
    });

  useEffect(() => {
    if (insertEmptyContentRowCalled || !campaigns || !settings) return;
    if (!user?.id) return; // sometimes the route changes before the user is loaded

    if (settings.contents.length === 0) {
      insertEmptyContentRow({
        variables: {
          settingId: Number(settingId),
          campaignId: Number(campaigns[0].id),
          campaignUserId: user?.id ?? 0
        }
      });
    }
  }, [insertEmptyContentRowCalled, settingId, campaigns, user, insertEmptyContentRow, settings]);

  const navigate = useNavigate();

  const contentState = (() => {
    const content = settings?.contents[0];
    const itemStates: (string | null | undefined)[] = [];
    if (content?.content_preapproval_caption) {
      itemStates.push(content.content_preapproval_caption.caption_state);
    }
    if (
      Array.isArray(content?.campaign_preapproval_content_medias) &&
      content?.campaign_preapproval_content_medias.length > 0
    ) {
      itemStates.push(...content.campaign_preapproval_content_medias.map(m => m.media_state));
    }

    return getContentAggregateState(itemStates) as CONTENT_APPROVAL_STATE;
  })();

  const steps = [
    "upload",
    ...((settings?.type !== CONTENT_TYPES.STORY.value
      ? ["caption"]
      : []) as CONTENT_APPROVAL_STEP[]),
    "success"
  ] as CONTENT_APPROVAL_STEP[];

  const prevStep = ((): CONTENT_APPROVAL_STEP | "back" | null => {
    const index = steps.indexOf(activeStep);
    if (index === -1) return null;
    if (index === 0) return "back";
    return steps[index - 1] ?? null;
  })();

  const nextStep = ((): CONTENT_APPROVAL_STEP | null => {
    const index = steps.indexOf(activeStep);
    if (index === -1) return null;
    return steps[index + 1] ?? null;
  })();

  const goBackToOverview = () => {
    setActiveStep("upload");
    navigate(`/preapproval/${campaignStub}/${campaignUserHash}`);
  };

  const handleSubmit = () => {
    if (!settings || !user) return;

    const { content_preapproval_caption, campaign_preapproval_content_medias } =
      settings.contents[0];

    const allStates = [
      ...(content_preapproval_caption?.caption_state ?? []),
      ...campaign_preapproval_content_medias.map(m => m.media_state)
    ].filter(l => Boolean(l)) as string[];

    let mediasToChange: typeof campaign_preapproval_content_medias = [];
    let captionToChange: typeof content_preapproval_caption | null = null;

    if (allStates.includes(CONTENT_APPROVAL_STATE.MANAGER_REJECTED)) {
      // when the manager has rejected any of the items, we need to only change the state at those rejected items
      // there is an edge case when content is rejected but creator also adds new media items

      mediasToChange = campaign_preapproval_content_medias.filter(m =>
        creatorEditing(m.media_state)
      );

      captionToChange = creatorEditing(content_preapproval_caption?.caption_state)
        ? content_preapproval_caption
        : null;
    } else {
      // at initial upload, we change the state at all items

      mediasToChange = campaign_preapproval_content_medias;
      captionToChange = content_preapproval_caption;
    }

    insertApprovalLog({
      variables: {
        objects: [
          ...mediasToChange.map(m => ({ campaign_preapproval_content_media_id: m.id })),

          ...(captionToChange ? [{ content_preapproval_caption_id: captionToChange.id }] : [])
        ].map(o => ({
          ...o,
          action_by_campaign_user_id: Number(user.id),
          state: CONTENT_APPROVAL_STATE.MANAGER_REVIEWING,
          target: "relatable"
        }))
      }
    });
  };

  const handleNextStep = () => {
    if (nextStep === "success") {
      if (creatorEditing(contentState)) {
        handleSubmit();
      }
    } else if (nextStep) {
      setActiveStep(nextStep);
    }
  };

  const handlePrevStep = () => {
    if (prevStep === "back") {
      goBackToOverview();
      return;
    }

    if (prevStep) {
      setActiveStep(prevStep);
    }
  };

  if (getCampaignError || getContentStatusError) {
    return <ErrorComponent message={getCampaignError?.message ?? getContentStatusError?.message} />;
  }

  if (getCampaignLoading || !settings?.id || settings?.contents.length === 0 || !user?.id) {
    return <Loader />;
  }

  return (
    <Stepper
      activeStep={activeStep}
      onPrevStep={handlePrevStep}
      nextStep={nextStep}
      onNextStep={handleNextStep}
      canProceed={canProceed}
      contentState={contentState}
      loadingAddComment={insertApprovalLogLoading}
    />
  );
};
