import type { FC } from "react";

import styled from "@emotion/styled";

import {
  ArrowLeft,
  ArrowRight,
  ChatBubble,
  Check,
  Close,
  Download,
  Photo
} from "@mui/icons-material";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";
export const ContentItemHeader: FC<{
  mediaStatus: CONTENT_APPROVAL_STATE;
  onMoveMedia?: (direction: "right" | "left") => void;
  onDownload: () => void;
  index: number;
  numSlides: number;
  editable: boolean;
  numComments: number;
}> = ({ mediaStatus, onMoveMedia, onDownload, index, numSlides, editable, numComments }) => {
  return (
    <Root $status={mediaStatus}>
      <div className="left">
        <Photo style={{ marginRight: 6 }} />
        {index + 1}/{numSlides}
        {numComments > 0 && (
          <>
            <ChatBubble style={{ margin: "0 6px 0 12px" }} />
            {numComments}
          </>
        )}
      </div>
      <div className="right">
        <ActionButton type="button" onClick={onDownload} title="Download">
          <Download fontSize="large" />
        </ActionButton>
        {editable && numSlides > 1 && (
          <>
            {index !== 0 && (
              <ActionButton type="button" onClick={() => onMoveMedia?.("left")} title="Move left">
                <ArrowLeft fontSize="large" />
              </ActionButton>
            )}
            {index !== numSlides - 1 && (
              <ActionButton type="button" onClick={() => onMoveMedia?.("right")} title="Move right">
                <ArrowRight fontSize="large" />
              </ActionButton>
            )}
          </>
        )}
        {mediaStatus === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING && (
          <Check fontSize="large" style={{ marginLeft: 8 }} />
        )}
        {mediaStatus === CONTENT_APPROVAL_STATE.MANAGER_REJECTED && !editable && (
          <Close className="hover" fontSize="large" />
        )}
      </div>
    </Root>
  );
};

const Root = styled.div<{ $status: string }>`
  width: 100%;
  height: 40px;
  border: 1px solid ${palette.gray[20]};
  border-bottom: 0px;
  padding: 0 10px 0 18px;
  ${({ $status }) => {
    let color = palette.gray.white as string;
    let background: string;
    if ([CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING].includes($status as CONTENT_APPROVAL_STATE)) {
      background = palette.primary.green;
    } else if ($status === CONTENT_APPROVAL_STATE.MANAGER_REJECTED) {
      background = palette.primary.gold;
    } else {
      color = palette.gray.black;
      background = palette.gray.white;
    }

    return { color, background };
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hover {
    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
`;

const ActionButton = styled.button`
  border: 0;
  background: inherit;
  cursor: pointer;
  margin: 0;
  margin-left: 5px;
  padding: 10px 6px;
  color: inherit;
  &:last-child {
    margin-right: 0;
  }
`;
