import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatorDashboardQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CreatorDashboardQuery = { __typename?: 'Query', creatorDashboard?: { __typename?: 'CreatorDashboardResponse', avg_engagement?: number | null, campaigns?: any | null, followed_by?: number | null, hasFbAccessToken?: boolean | null, profileMatchesAccessToken?: boolean | null, profile_picture?: string | null, thumbnail?: string | null, username?: string | null, instagramBusinessAccountId?: string | null, permissions?: Array<string | null> | null } | null };


export const CreatorDashboardDocument = gql`
    query CreatorDashboard {
  creatorDashboard {
    avg_engagement
    campaigns
    followed_by
    hasFbAccessToken
    profileMatchesAccessToken
    profile_picture
    thumbnail
    username
    instagramBusinessAccountId
    permissions
  }
}
    `;

/**
 * __useCreatorDashboardQuery__
 *
 * To run a query within a React component, call `useCreatorDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreatorDashboardQuery(baseOptions?: Apollo.QueryHookOptions<CreatorDashboardQuery, CreatorDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreatorDashboardQuery, CreatorDashboardQueryVariables>(CreatorDashboardDocument, options);
      }
export function useCreatorDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorDashboardQuery, CreatorDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreatorDashboardQuery, CreatorDashboardQueryVariables>(CreatorDashboardDocument, options);
        }
export function useCreatorDashboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreatorDashboardQuery, CreatorDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreatorDashboardQuery, CreatorDashboardQueryVariables>(CreatorDashboardDocument, options);
        }
export type CreatorDashboardQueryHookResult = ReturnType<typeof useCreatorDashboardQuery>;
export type CreatorDashboardLazyQueryHookResult = ReturnType<typeof useCreatorDashboardLazyQuery>;
export type CreatorDashboardSuspenseQueryHookResult = ReturnType<typeof useCreatorDashboardSuspenseQuery>;
export type CreatorDashboardQueryResult = Apollo.QueryResult<CreatorDashboardQuery, CreatorDashboardQueryVariables>;