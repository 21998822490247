import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { NotAllowed } from "./NotAllowed";
import { StartPage } from "./StartPage";

export const NoTokenUser: React.FC = () => {
  const { userHash } = useParams<{ userHash: string }>();

  return (
    <Root>{userHash === "notAllowed" ? <NotAllowed reason={userHash} /> : <StartPage />}</Root>
  );
};

const Root = styled.div`
  padding-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
