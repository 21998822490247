export const palette = {
  gray: {
    black: "#1E1E1E",
    100: "#000000",
    90: "#1E1E1E",
    80: "#3C3C3C",
    70: "#5A5A5A",
    60: "#787878",
    50: "#969696",
    40: "#B4B4B4",
    30: "#D2D2D2",
    20: "#EAEAEA",
    10: "#F7F7F7",
    whiteOff: "#F7F7F7",
    white: "#FFFFFF"
  },

  primary: {
    red: "#FF0202",
    purple: "#7615E3",
    blue: "#0C54C9",
    green: "#248A9A",
    gold: "#FF823E"
  },

  secondary: {
    red: "#FF7070",
    purple: "#C28CFF",
    blue: "#4DA3FF",
    green: "#36AFB1",
    gold: "#FE9F30"
  },

  tertiary: {
    red: "#FFD6D6",
    purple: "#EDE7FF",
    blue: "#E0F6FF",
    green: "#DCFCF8",
    gold: "#FFEEDA"
  },

  social: {
    youtube: "#FC0D1B",
    instagram: "#8134AF",
    tiktok: "#6AC3C9",
    snapchat: "#FFFC00"
  }
} as const;
