export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const numberWithCommas = (number: number | null | undefined) => {
  if (!number) return "0";
  const numStr = number.toString();
  const wholePart = numStr.includes(".") ? numStr.split(".")[0] : numStr;
  const decimalPart = numStr.includes(".") ? `.${numStr.split(".")[1]}` : "";
  return wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimalPart;
};

export const addCommasIfNumber = (str: string): string => {
  const trimmedField = str.trim();
  if (!/^[0-9.\s]+%?$/.test(trimmedField)) return str;
  const hasPercentage = trimmedField.endsWith("%");
  const number = Number(hasPercentage ? trimmedField.slice(0, -1) : hasPercentage);
  const formatted = numberWithCommas(number);
  return formatted + (hasPercentage ? "%" : "");
};

export function getOrdinalSuffixOf(i: number) {
  if (i % 10 === 1 && i % 100 !== 11) {
    return `${i}st`;
  }
  if (i % 10 === 2 && i % 100 !== 12) {
    return `${i}nd`;
  }
  if (i % 10 === 3 && i % 100 !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export const prettifyNumber = (
  n: number | null | undefined,
  opts?: { percentages?: boolean; rounded?: boolean }
) => {
  if (n === 0) {
    return opts?.percentages ? "0%" : "0";
  }
  // biome-ignore lint/style/useNumberNamespace:
  if (Number.isNaN(n) || !n || n === Infinity) return "";

  if (opts?.percentages) {
    if (opts.rounded) {
      return `${Math.round(n * 100)}%`;
    }
    return `${Math.round(n * 10000) / 100}%`;
  }

  if (opts?.rounded) {
    return Math.round(n).toLocaleString();
  }
  return (Math.round(n * 100) / 100).toLocaleString();
};

export const prettifyPercentages = ({
  total,
  of
}: {
  total: number | undefined | null;
  of: number | undefined | null;
}) => {
  if (!of || !total) return "";
  return `${Math.floor((of / total) * 10000) / 100}%`;
};

export const calculatePercentages = ({
  total,
  of
}: {
  total: number | undefined | null;
  of: number | undefined | null;
}) => {
  if (!of || !total) return 0;
  return Math.floor((of / total) * 10000) / 100;
};

export const prettifyDate = (
  n: string | undefined | null,
  opts?: { full?: boolean; utc?: boolean }
) => {
  if (!n) return "-";
  const date = new Date(n);
  if (Number.isNaN(date.getTime())) return "";

  if (opts?.full) {
    return date.toLocaleString("en-SE", { timeZone: opts?.utc ? "UTC" : "Europe/Stockholm" });
  }

  return date.toLocaleDateString("en-SE", { timeZone: opts?.utc ? "UTC" : "Europe/Stockholm" });
};

export const maybePluralize = (
  count: number,
  { noun, suffix = "s" }: { noun: string; suffix?: string }
): string => `${count} ${noun}${count !== 1 ? suffix : ""}`;

export const truncateText = (str: string, chars: number) => {
  if (str.length < chars) return str;
  const nextSpace = str.indexOf(" ", chars);
  if (nextSpace !== -1) return `${str.slice(0, nextSpace)}...`;
  return `${str.slice(0, chars)}...`;
};

export const truncateFileName = (fileName: string, maxLength = 30) => {
  if (fileName.length < maxLength) return fileName;

  const charList = fileName.split(".");
  const extension = charList[charList.length - 1];

  return `${fileName.slice(0, maxLength)}….${extension}`;
};

const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

export const getCountryFullName = (code: string | null | undefined) => {
  if (!code) return "-";
  try {
    return regionNames.of(code);
  } catch {
    return code;
  }
};
