import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";

import { Textfield } from "modules/approval/components/atoms";

export const Caption: React.FC<{
  captionState: CONTENT_APPROVAL_STATE;
  onInputChange: React.ChangeEventHandler<HTMLInputElement>;
  caption: string;
  disabled: boolean;
  isLoading: boolean;
}> = ({ captionState, onInputChange, caption = "", disabled, isLoading }) => {
  const isRejected = captionState === CONTENT_APPROVAL_STATE.MANAGER_REJECTED;

  return (
    <Root>
      <span style={{ display: "inline-flex", alignItems: "center" }}>
        <h4
          style={{
            margin: 0,
            color: isRejected ? palette.primary.red : undefined
          }}
        >
          Caption {!disabled ? "*" : ""} {isRejected ? "(needs updates)" : ""}
        </h4>
      </span>

      {disabled ? (
        <p style={{ whiteSpace: "pre-wrap" }}>{caption}</p>
      ) : (
        <>
          <span
            style={{
              padding: "5px 0 15px",
              display: "inline-flex",
              fontSize: 13,
              alignItems: "center"
            }}
          >
            Mandatory hashtags are prefilled{" "}
            {isLoading && (
              <CircularProgress style={{ marginLeft: 5, color: palette.primary.red }} />
            )}
          </span>

          <Textfield
            placeholder="Write a caption"
            onChange={onInputChange}
            multiline
            maxRows={10}
            minRows={4}
            value={caption || ""}
          />
        </>
      )}
    </Root>
  );
};

const Root = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  background-color: ${palette.gray[10]};
`;
