import { useState } from "react";

import { VIDEO_FILE_TYPES } from "@relatable/helpers/index";

import { changeOrientation } from "./Step/MediaUpload/changeOrientation";
import {
  ContentMediaDocument,
  ContentStatusDocument,
  useInsertContentApprovalLogMutation,
  useReplaceContentApprovalContentMediaMutation
} from "./Step/generated";
import {
  useInsertMediaMutation,
  useSignUploadUrlMutation,
  useTriggerMp4ConversionMutation
} from "./generated";
import { uploadWithProgress } from "./uploadWithProgress";

export interface UseUploadMediaParams {
  stub: string;
  username: string;
  contentID: number;
  type: string;
  index?: number;
  mediaId?: number;
  campaignUserId?: number;
}

type useUploadMediaReturnType = [
  (file: File, params: UseUploadMediaParams) => Promise<void>,
  { error?: Error; progress: null | number; loading: boolean }
];

export const useUploadMedia = (): useUploadMediaReturnType => {
  const [progress, setProgress] = useState<null | number>(null);
  const [error, setError] = useState<Error>();

  const [insertMedia, { loading: insertMediaLoading, error: insertMediaError }] =
    useInsertMediaMutation({
      refetchQueries: [ContentMediaDocument],
      awaitRefetchQueries: true
    });

  const [insertApprovalLog] = useInsertContentApprovalLogMutation({
    refetchQueries: [ContentStatusDocument, ContentMediaDocument],
    awaitRefetchQueries: true
  });

  const [signUploadUrlMutation] = useSignUploadUrlMutation();
  const [triggerMP4Conversion] = useTriggerMp4ConversionMutation();

  const [replaceMedia, { loading: replaceMediaLoading, error: replaceMediaError }] =
    useReplaceContentApprovalContentMediaMutation({
      refetchQueries: [ContentMediaDocument]
    });

  const uploadMedia = async (file: File, params: UseUploadMediaParams) => {
    const { stub, username, contentID, type, index, mediaId, campaignUserId } = params;
    setProgress(0);

    const fileType = (file.name.match(/\.\w+$/)?.[0] ?? "").toLowerCase().slice(1);

    const normalizedFile = VIDEO_FILE_TYPES.includes(fileType)
      ? file
      : await changeOrientation(file);

    const timestamp = new Date().valueOf();
    const extension = normalizedFile.name.split(".").pop()?.toLowerCase();
    if (!extension) throw new Error("Invalid file extension");

    try {
      const { data } = await signUploadUrlMutation({
        variables: {
          input: {
            fileType: normalizedFile.type,
            prefix: "APPROVAL_UPLOADS",
            fileName: `${stub}/${username}/content-id-${contentID}-${type}-${timestamp}.${extension}`
          }
        }
      });

      if (!data?.signUploadUrl) {
        throw Error("Something went wrong when signing a file for the upload");
      }

      await uploadWithProgress({
        method: "PUT",
        url: data.signUploadUrl.signedUploadUrl,
        headers: { "content-type": normalizedFile?.type },
        onUploadProgress: x => setProgress(x),
        body: normalizedFile
      });

      if (mediaId) {
        // backend takes care of storing the old filename in the history
        await replaceMedia({
          variables: {
            mediaId,
            filename: data.signUploadUrl.url
          }
        });
      } else {
        await insertMedia({
          variables: {
            object: {
              content_id: contentID,
              filename: data.signUploadUrl.url,
              index,
              ...(Boolean(mediaId) && {
                id: mediaId,
                updated: new Date().toISOString()
              })
            }
          }
        });
      }

      const supportedExtensions = ["mp4", "jpeg", "jpg", "png", "tiff", "gif", "webp"];
      if (!supportedExtensions.includes(extension)) {
        // we don't need to wait for this to finish here
        triggerMP4Conversion();
      }
    } catch (err) {
      console.error(err);
      setError(err);
    }

    setProgress(null);
  };

  const loading = progress !== null;

  return [
    uploadMedia,
    {
      error: error ?? insertMediaError ?? replaceMediaError,
      loading: loading || insertMediaLoading || replaceMediaLoading,
      progress
    }
  ];
};
