import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignUploadUrlMutationVariables = Types.Exact<{
  input: Types.SignUploadUrlInput;
}>;


export type SignUploadUrlMutation = { __typename?: 'Mutation', signUploadUrl: { __typename?: 'SingedUpload', url: string, signedUploadUrl: string } };

export type TriggerMp4ConversionMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type TriggerMp4ConversionMutation = { __typename?: 'Mutation', triggerMP4Conversion?: void | null };

export type InsertMediaMutationVariables = Types.Exact<{
  object: Types.Campaign_Preapproval_Content_Media_Insert_Input;
}>;


export type InsertMediaMutation = { __typename?: 'Mutation', insert_campaign_preapproval_content_media_one?: { __typename?: 'campaign_preapproval_content_media', id: number } | null };

export type SendSlackNotificationMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
  text: Types.Scalars['String']['input'];
  channel?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SendSlackNotificationMutation = { __typename?: 'Mutation', sendSlackNotification?: { __typename?: 'SendSlackNotificationReturn', error?: string | null } | null };


export const SignUploadUrlDocument = gql`
    mutation signUploadUrl($input: SignUploadUrlInput!) {
  signUploadUrl(input: $input) {
    url
    signedUploadUrl
  }
}
    `;
export type SignUploadUrlMutationFn = Apollo.MutationFunction<SignUploadUrlMutation, SignUploadUrlMutationVariables>;

/**
 * __useSignUploadUrlMutation__
 *
 * To run a mutation, you first call `useSignUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUploadUrlMutation, { data, loading, error }] = useSignUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<SignUploadUrlMutation, SignUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUploadUrlMutation, SignUploadUrlMutationVariables>(SignUploadUrlDocument, options);
      }
export type SignUploadUrlMutationHookResult = ReturnType<typeof useSignUploadUrlMutation>;
export type SignUploadUrlMutationResult = Apollo.MutationResult<SignUploadUrlMutation>;
export type SignUploadUrlMutationOptions = Apollo.BaseMutationOptions<SignUploadUrlMutation, SignUploadUrlMutationVariables>;
export const TriggerMp4ConversionDocument = gql`
    mutation triggerMP4Conversion {
  triggerMP4Conversion
}
    `;
export type TriggerMp4ConversionMutationFn = Apollo.MutationFunction<TriggerMp4ConversionMutation, TriggerMp4ConversionMutationVariables>;

/**
 * __useTriggerMp4ConversionMutation__
 *
 * To run a mutation, you first call `useTriggerMp4ConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerMp4ConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerMp4ConversionMutation, { data, loading, error }] = useTriggerMp4ConversionMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerMp4ConversionMutation(baseOptions?: Apollo.MutationHookOptions<TriggerMp4ConversionMutation, TriggerMp4ConversionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerMp4ConversionMutation, TriggerMp4ConversionMutationVariables>(TriggerMp4ConversionDocument, options);
      }
export type TriggerMp4ConversionMutationHookResult = ReturnType<typeof useTriggerMp4ConversionMutation>;
export type TriggerMp4ConversionMutationResult = Apollo.MutationResult<TriggerMp4ConversionMutation>;
export type TriggerMp4ConversionMutationOptions = Apollo.BaseMutationOptions<TriggerMp4ConversionMutation, TriggerMp4ConversionMutationVariables>;
export const InsertMediaDocument = gql`
    mutation insertMedia($object: campaign_preapproval_content_media_insert_input!) {
  insert_campaign_preapproval_content_media_one(object: $object) {
    id
  }
}
    `;
export type InsertMediaMutationFn = Apollo.MutationFunction<InsertMediaMutation, InsertMediaMutationVariables>;

/**
 * __useInsertMediaMutation__
 *
 * To run a mutation, you first call `useInsertMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMediaMutation, { data, loading, error }] = useInsertMediaMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertMediaMutation(baseOptions?: Apollo.MutationHookOptions<InsertMediaMutation, InsertMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMediaMutation, InsertMediaMutationVariables>(InsertMediaDocument, options);
      }
export type InsertMediaMutationHookResult = ReturnType<typeof useInsertMediaMutation>;
export type InsertMediaMutationResult = Apollo.MutationResult<InsertMediaMutation>;
export type InsertMediaMutationOptions = Apollo.BaseMutationOptions<InsertMediaMutation, InsertMediaMutationVariables>;
export const SendSlackNotificationDocument = gql`
    mutation sendSlackNotification($campaignId: Int!, $text: String!, $channel: String) {
  sendSlackNotification(campaign_id: $campaignId, text: $text, channel: $channel) {
    error
  }
}
    `;
export type SendSlackNotificationMutationFn = Apollo.MutationFunction<SendSlackNotificationMutation, SendSlackNotificationMutationVariables>;

/**
 * __useSendSlackNotificationMutation__
 *
 * To run a mutation, you first call `useSendSlackNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSlackNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSlackNotificationMutation, { data, loading, error }] = useSendSlackNotificationMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      text: // value for 'text'
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useSendSlackNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendSlackNotificationMutation, SendSlackNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSlackNotificationMutation, SendSlackNotificationMutationVariables>(SendSlackNotificationDocument, options);
      }
export type SendSlackNotificationMutationHookResult = ReturnType<typeof useSendSlackNotificationMutation>;
export type SendSlackNotificationMutationResult = Apollo.MutationResult<SendSlackNotificationMutation>;
export type SendSlackNotificationMutationOptions = Apollo.BaseMutationOptions<SendSlackNotificationMutation, SendSlackNotificationMutationVariables>;