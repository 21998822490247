import type { ReactNode } from "react";

import styled from "@emotion/styled";

export const NoImage: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <Root>{children}</Root>
);

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  display: flex;
  font-size: 1rem;
  text-align: center;
`;
