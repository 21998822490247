import "react-responsive-carousel/lib/styles/carousel.min.css";

import type { FC } from "react";

import styled from "@emotion/styled";
import type { Content_Preapproval_Type_Enum } from "@relatable/gql/generated-base";
import { CONTENT_TYPES } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";
import { Carousel as ReactResponsiveCarousel } from "react-responsive-carousel";

import type { ContentMediaQuery } from "modules/approval/components/ContentApproval/Stepper/Step/generated";
import type { UseUploadMediaParams } from "modules/approval/components/ContentApproval/Stepper/useUploadMedia";

import { ContentDisplay } from "./ContentDisplay/ContentDisplay";
import { FilePicker } from "./FilePicker";

export const Carousel: FC<{
  contentType: Content_Preapproval_Type_Enum;
  medias: ContentMediaQuery["medias"];
  onUpdateSlideIndex: (index: number) => void;
  progress: number | null;
  slideIndex: number;
  uploading: boolean;
  editable: boolean;
  acceptedFiles: string[];
  onFileUploadChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  uploadParams: UseUploadMediaParams;
}> = ({
  contentType,
  medias,
  onUpdateSlideIndex,
  progress,
  slideIndex,
  uploading,
  editable,
  acceptedFiles,
  onFileUploadChange,
  uploadParams
}) => {
  const isCurrentItemTheFilepicker = slideIndex === medias.length;

  const renderFileUploadSlide = () => {
    if (uploading) {
      return (
        <ContentDisplay
          key="uploading"
          index={medias.length}
          progress={progress}
          editable={false}
          active={false}
          media={null}
          nextMedia={null}
          numSlides={medias.length}
          numComments={0}
          uploading
          uploadParams={uploadParams}
          acceptedTypes={acceptedFiles}
        />
      );
    }

    return (
      <FilePickerContainer
        $isCurrentItemTheFilepicker={isCurrentItemTheFilepicker}
        key="file-picker"
      >
        <FilePicker
          onFileUploadChange={onFileUploadChange}
          acceptedFiles={acceptedFiles}
          disabled={!editable}
        />
        {isCurrentItemTheFilepicker && (
          <SCUploadInstruction>Upload content for approval</SCUploadInstruction>
        )}
      </FilePickerContainer>
    );
  };

  // needed to broken out, as rendering a 'null' element inside the carousel
  // resulted in an empty slide
  const carouselElements = [
    ...medias.map((m, index) => (
      <ContentDisplay
        key={m.id}
        isStory={contentType === CONTENT_TYPES.STORY.value}
        editable={editable}
        active={index === slideIndex}
        index={index}
        media={m}
        nextMedia={medias[index + 1] ?? null}
        numComments={m.logs.filter(l => Boolean(l.text)).length}
        numSlides={medias.length}
        uploading={uploading}
        uploadParams={{ ...uploadParams, mediaId: m.id }}
        acceptedTypes={acceptedFiles}
      />
    )),
    ...(editable ? [renderFileUploadSlide()] : [])
  ];

  return (
    <Root
      autoPlay={false}
      centerMode
      centerSlidePercentage={95}
      dynamicHeight={false}
      emulateTouch
      interval={1e20} /* Due to a bug, autoPlay={false} is not enough */
      onChange={index => onUpdateSlideIndex(index)}
      showArrows={false}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      selectedItem={slideIndex}
      useKeyboardArrows
      swipeable
    >
      {...carouselElements}
    </Root>
  );
};

const Root = styled(ReactResponsiveCarousel)`
  width: 100%;
  .carousel {
    .slide {
      &.selected {
        border-radius: 0 0 5px 5px;
        overflow: hidden;
      }
    }
  }
`;

const FilePickerContainer = styled.div<{ $isCurrentItemTheFilepicker: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ $isCurrentItemTheFilepicker }) =>
    $isCurrentItemTheFilepicker ? "center" : "initial"};
`;

const SCUploadInstruction = styled.span`
  margin-top: 12px;
  text-align: center;
  color: ${palette.gray.black};
`;
