import type { FC, PropsWithChildren } from "react";

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

export * from "./utils";

export const getGqlClient = ({ headers }: { headers?: any }) => {
  const httpLink = new HttpLink({
    uri: process.env.GRAPHQL_API,
    credentials: "include",
    headers
  });

  const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
    forward(operation);
  });

  const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network"
      }
    }
  });

  return client;
};

export const GqlProvider: FC<PropsWithChildren<{ client: ApolloClient<unknown> }>> = ({
  children,
  client
}) => <ApolloProvider client={client}>{children}</ApolloProvider>;
