export * from "./approvalLogs";
export * from "./date";
export * from "./format";
export * from "./timeline";
export * from "./types";
export * from "./user";

export const isNumeric = (num: string): boolean => /^[0-9,.\s$%-]+$/.test(num);

export const isInternalLink = (url: string) =>
  url.startsWith("/") || /^https?:\/\/(?:.*\.)?relatable\.me/i.test(url ?? "");

// do it in O(n) instead of arr.sort
export const findGreatestElementIndexes = (arr: number[], count: number) => {
  const greatestValues: number[] = [];

  for (let i = 0; i < arr.length; i++) {
    greatestValues.push(i);
    if (greatestValues.length > count) {
      greatestValues.sort((a, b) => {
        return arr[b] - arr[a];
      });
      greatestValues.pop();
    }
  }
  return greatestValues;
};

export const unique = <T extends number | string | undefined | null>(arr: T[]): T[] =>
  arr.filter((value, index, self) => self.indexOf(value) === index);

export const uniqBy = <T>(arr: T[], iteratee: ((args: T) => any) | keyof NonNullable<T>) => {
  const iterateeFn = typeof iteratee === "function" ? iteratee : (item: any) => item[iteratee];
  return arr.filter((x, i, self) => i === self.findIndex(y => iterateeFn(x) === iterateeFn(y)));
};

export function shuffle(a: any[]) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [a[i], a[j]] = [a[j], a[i]];
  }
}

/** Fast randomized 8 char string (not crypto safe) */
export const rndString = () => `${Math.random().toString(36)}00000000`.slice(2, 8 + 2);
export const rndLongString = (n: number) => {
  if (!Number.isSafeInteger(n) || n < 1) throw Error("Invalid length");

  let str = rndString();
  while (str.length < n) {
    str += rndString();
  }
  return str.slice(0, n);
};

export function uuidV4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const sum = (arr: number[]): number => arr.reduce((prev, curr) => prev + curr, 0);

export const sleep = (seconds: number) =>
  new Promise(resolve => {
    setTimeout(resolve, seconds * 1000);
  });

export const endsWithAny = ({
  suffixes,
  string = ""
}: {
  suffixes: string[];
  string: string;
}): boolean => {
  const strLowerCase = string.toLowerCase();
  return suffixes.some(suffix => string && strLowerCase.endsWith(suffix.toLowerCase()));
};

export const PHOTO_FILE_TYPES = ["png", "jpg", "jpeg", "gif", "bmp", "tiff"];
export const VIDEO_FILE_TYPES = ["mov", "mpg", "mpeg", "mp4", "wmv", "avi"];

export const isVideo = (fileName: string | null | undefined) =>
  endsWithAny({ suffixes: VIDEO_FILE_TYPES, string: fileName ?? "" });

// https://support.google.com/docs/answer/3093426?hl=en
/** Rounds one number to the nearest integer multiple of another. */
export const mround = (value: number, factor = 1) => factor * Math.round(value / factor);

export const getFileType = (filename: string) => {
  const clearedUrl = filename.split("?")[0].split("&")[0];
  const lastUrlPart = clearedUrl.split("/").pop();
  if (!lastUrlPart) return "";
  const parts = lastUrlPart.split(".");
  if (parts.length === 1) return "";
  return `.${parts[parts.length - 1]}`;
};

export const sortBy = <A>(
  array: A[],
  by: keyof A,
  opts: { direction: "asc" | "desc"; isDate?: boolean }
): A[] => {
  return array?.sort((a, b) => {
    const nA = opts.isDate ? Number(new Date(a[by] as unknown as string)) : a[by];
    const nB = opts.isDate ? Number(new Date(b[by] as unknown as string)) : b[by];

    if (opts.direction === "desc") {
      if (typeof nA === "string" && typeof nB === "string") {
        const sortResult = Intl.Collator().compare(nA, nB);
        return sortResult === 1 ? -1 : 1;
      }

      if (nA > nB) return -1;
      if (nA < nB) return 1;
      return 0;
    }

    if (typeof nA === "string" && typeof nB === "string") return Intl.Collator().compare(nA, nB);

    if (nA < nB) return -1;
    if (nA > nB) return 1;
    return 0;
  });
};
