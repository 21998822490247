import { StrictMode } from "react";

import { createRoot } from "react-dom/client";

import { UIProvider } from "@relatable/ui/UIProvider";
import { RouterProvider } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { router } from "./routes";
document.addEventListener("DOMContentLoaded", () => {
  console.log(`Creator app [${process.env.COMMIT_HASH}] ${process.env.BUILD_DATE}`);

  const container = document.getElementById("root");
  if (!container) throw new Error("Root element not found");
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <UIProvider>
        <RouterProvider router={router} fallbackElement={<Loader />} />
      </UIProvider>
    </StrictMode>
  );
});
