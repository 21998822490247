import { getUserLabel, getUserLink, getUserProfilePicture } from "@relatable/helpers/user";

import { useMeQuery } from "./generated";

export const useUser = ({
  skip = false
}: {
  skip?: boolean;
} = {}) => {
  const { data, loading } = useMeQuery({
    skip,
    onCompleted: completedData => {
      if (completedData.campaign_user.length !== 1) {
        window.location.pathname = "/404";
      }
    }
  });

  if (loading || !data?.campaign_user) {
    return null;
  }

  // The onboarding project should always use the creator auth token, which only has access to a single campaign_user
  // When there are 2 results here, we should invalidate the token, because the auth handing is broken somehow
  if (data.campaign_user.length > 1) {
    throw new Error("Fatal auth error.");
  }

  const user = data.campaign_user[0];
  const { platform } = user.campaign;

  const username = getUserLabel(user.user);
  const platformUrl = getUserLink(user.user);
  const profilePicture = getUserProfilePicture(user.user);

  return { ...user, profilePicture, username, platformUrl, platform };
};
