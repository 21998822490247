import { CrisisAlert, DoNotDisturb } from "@mui/icons-material";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import type { FC } from "react";
import { useLocation } from "react-router-dom";

export const FBNoBusinessAccount: FC = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <h1>Facebook Business account has not been found</h1>

      <CrisisAlert style={{ color: palette.primary.red, fontSize: 46 }} />
      <DoNotDisturb style={{ color: palette.primary.red, fontSize: 46 }} />

      <p>
        We're very sorry that this error popped up.
        <br />
        There are 2 things we would love to ask you to check before you reach out for help.
      </p>

      <p>
        <h4>
          1. Creators need to create a Facebook page and connect it with their business account on
          Instagram.
        </h4>
        This is most common issue. You can check how to connect Instagram to Facebook page at
        Facebook help page.
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.facebook.com/business/help/connect-instagram-to-page"
          style={{ cursor: "pointer" }}
        >
          {" "}
          Learn more
        </a>
        <h4 style={{ marginTop: 10 }}>2. Make sure you account is of business type.</h4>
        You can check how to convert account into business (professional) at Instagram help page.
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://help.instagram.com/502981923235522"
          style={{ cursor: "pointer" }}
        >
          {" "}
          Learn more
        </a>
      </p>

      <Button href={pathname.replace("/fb-no-business-account", "/api")}>Try again</Button>
    </div>
  );
};
