import styled from "@emotion/styled";
import { Navigate, Route, Routes } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { useUser } from "components/hooks/useUser";

import { ContentList } from "./ContentList/ContentList";
import { StepperContainer } from "./Stepper/StepperContainer";

export const ContentApproval: React.FC = () => {
  const user = useUser();

  if (!user?.id) return <Loader />;

  return (
    <Root>
      <Routes>
        <Route path="/" element={<ContentList />} />

        <Route path="/:settingId" element={<StepperContainer />} />
        <Route element={<Navigate to="/" />} />
      </Routes>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
`;
