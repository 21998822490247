import styled from "@emotion/styled";

export const NotAllowed: React.FC<{ reason: "notAllowed" | "noBusinessAccount" }> = ({
  reason
}) => {
  if (reason === "noBusinessAccount") {
    return (
      <NotAllowedContainer>
        <h4>🤔</h4>
        <p>
          Hi, to access our service, you need to have an Instagram business account. You don&apos;t
          seem to have one and/or you haven&apos;t connected it to Facebook.
        </p>
        <p className="gray-text">
          Wait, don&apos;t fret! It is free and easy to setup a business account in the Instagram
          mobile app.
        </p>
        <hr />
        <img src="/images/relatable_circle_black.svg" alt="logo" />
      </NotAllowedContainer>
    );
  }

  return (
    <NotAllowedContainer>
      <h4>✋</h4>
      <p>
        Hi, we are flattered that you decided to stop by. Unfortunately our service is available by{" "}
        <b>invitation only</b>.
      </p>
      <p className="gray-text">
        Received an invitation? Please use the link provided in the e-mail. If you still end up
        here, don&apos;t hesitate to contact us.
      </p>
      <p className="link">
        <a className="waves-effect waves-light btn" href="/signup">
          Join our VIP list
        </a>{" "}
        or read more about{" "}
        <a className="waves-effect waves-light btn" href="https://relatable.me">
          Relatable
        </a>
      </p>
      <hr />
      <img src="/images/relatable_circle_black.svg" alt="logo" />
    </NotAllowedContainer>
  );
};

const NotAllowedContainer = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 36px;
  .gray-text,
  .link,
  .link a {
    color: #999;
  }

  img {
    margin-top: 20px;
    width: 30%;
    opacity: 0.18;
  }
  hr {
    border: 0;
    height: 1px;
    width: 100%;
    background-color: #ddd;
    margin-top: 100px;
  }

  h4 {
    font-size: 50px;
    margin: 0px;
    margin-bottom: 2px;
  }

  span {
    text-transform: uppercase;
  }

  p,
  span,
  a {
    font-size: 14px;
  }

  a {
    display: inline-block;
  }
`;
