import type { FC, PropsWithChildren } from "react";

import { Global, ThemeProvider, css } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import { palette } from "./Palette";
import { SnackbarProvider } from "./Snackbar";
import { theme } from "./Theme";

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1640px",
  desktop: "2560px"
} as const;

const GlobalStyle = css`
  *,
  *:before,
  *:after,
  .MuiInputBase-root,
  .MuiInputBase-input.MuiInputBase-input,
  .MuiFormLabel-root.MuiFormLabel-root,
  .MuiInputBase-root.MuiInputBase-root,
  .MuiTableCell-root.MuiTableCell-root,
  .MuiButton-root.MuiButton-root,
  .MuiTooltip-tooltip.MuiTooltip-tooltip {
    font-family: "Manrope", "Helvetica Neue", "Arial", "sans-serif";
    letter-spacing: 0em;
    box-sizing: border-box;
  }

  .MuiDataGrid-cell {
    outline: none !important;
  }

  ul {
    margin: 0px;
  }

  html {
    font-size: 14px;
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
    font-family: "Manrope", sans-serif;
  }

  a {
    text-decoration: none;
    color: ${palette.primary.blue};
  }

  #app,
  html,
  body {
    margin: 0 auto;
    height: 100%;
  }

  @media only screen and (min-width: ${size.laptop}) {
    #app,
    html,
    body {
      overflow-x: hidden;
    }
  }
`;

export const UIProvider: FC<PropsWithChildren> = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <Global styles={GlobalStyle} />
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
);
