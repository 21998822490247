import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { useLocation } from "react-router-dom";

import { useEncodedUser } from "./useEncodedUser";

const SubTitle = styled.p`
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 600;
`;

export const StartStep = () => {
  const { user } = useEncodedUser();
  const { pathname } = useLocation();

  return (
    <div style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
      <p>Hey there, {user?.userLabel || "…"}!</p>
      <p>
        Great to have you onboard for this campaign. Before we get started please dedicate a few
        minutes here and it will save us both lots of time later. Let’s go!
      </p>
      <SubTitle>First time working with us? Read on below! 👇</SubTitle>

      <SubTitle>Who are we?</SubTitle>
      <p>
        We are Relatable! We help brands finding the perfect creators to collaborate with (that’s
        you!) and run the campaign in a way that’s easy and fun for everyone involved.
      </p>
      <SubTitle>What’s our process?</SubTitle>
      <p>
        What’s our process? Our goal is to make the process super easy for you and the brand. Here’s
        what we expect from you:
      </p>
      <ul style={{ textAlign: "left", margin: "auto" }}>
        <li>Fill in required info, connect with us and sign the agreement</li>
        <li>Read through the creative brief carefully</li>
        <li>Make sure you have understood the timeline</li>
        <li>If requested, share your synopsis with us</li>
        <li>Share your content with us</li>
        <li>Receive feedback on content and make updates if needed</li>
        <li>Publish your approved content + caption</li>
        <li>Share your statistics with us 48h after published content</li>
        <li>Send invoice and get paid</li>
      </ul>
      <p>
        We are here to support you all the way, so do not hesitate to reach out to your production
        manager. Let’s GO!
      </p>
      <Button
        href={pathname.replace("/start", user?.skipAPIVerification ? "/details" : "/api")}
        style={{ margin: "auto" }}
        size="medium"
      >
        Got it, next!
      </Button>
    </div>
  );
};
