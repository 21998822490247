import { creatorEditing } from "@relatable/helpers";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useParams } from "react-router-dom";

import { ErrorComponent } from "components/Layout/Error";
import { useUser } from "components/hooks/useUser";
import {
  type UseUploadMediaParams,
  useUploadMedia
} from "modules/approval/components/ContentApproval/Stepper/useUploadMedia";

import { useCampaignsQuery, useContentMediaQuery, useContentStatusQuery } from "../generated";
import { MediaUpload } from "./MediaUpload";

export const MediaUploadContainer: React.FC<{
  slideIndex: number;
  contentState: CONTENT_APPROVAL_STATE;
  onUpdateSlideIndex: (index: number) => void;
}> = ({ slideIndex, contentState, onUpdateSlideIndex }) => {
  const user = useUser();
  const snackbar = useSnackbar();
  const {
    data: { campaigns } = {}
  } = useCampaignsQuery();
  const campaign = campaigns?.[0];

  const { settingId } = useParams<{ settingId: string }>();
  const {
    data: { settings } = {}
  } = useContentStatusQuery({
    variables: { settingId: Number(settingId) }
  });
  const contentId = settings?.contents?.[0]?.id;
  const {
    data: { medias = [] } = {}
  } = useContentMediaQuery({
    variables: { contentId: contentId ?? 0 },
    skip: !contentId
  });

  const contentStatus = settings?.contents[0];
  const [uploadMedia, { loading: uploadMediaLoading, progress, error: uploadMediaError }] =
    useUploadMedia();

  const editable = creatorEditing(contentState);

  const baseUploadParams: UseUploadMediaParams = {
    stub: campaign?.stub ?? "",
    username: user?.username ?? "",
    contentID: contentStatus?.id ?? 0,
    type: settings?.type ?? "",
    campaignUserId: user?.id ?? 0
  };

  const handleFileUploadChange: (e: React.ChangeEvent<HTMLInputElement>) => void = async event => {
    try {
      if (!campaign || !user || !contentStatus || !settings || !medias) return;

      event.persist();
      const mediasLength = medias.length;

      const filesArr = Array.from(event.target.files ?? []);
      for (let index = 0; index < filesArr.length; index++) {
        const file = filesArr[index];

        const uploadParams: UseUploadMediaParams = {
          ...baseUploadParams,
          index: Math.max(...medias.map(m => m.index ?? 0), 0) + index + 1
        };

        uploadMedia(file, uploadParams);
        if (mediasLength) {
          onUpdateSlideIndex(slideIndex + 1);
        }
      }
    } catch {
      snackbar.error(
        "Something went wrong 😔 when uploading an image. Try to convert it online to a different extension or try a different image."
      );
    }
  };

  if (uploadMediaError) {
    return <ErrorComponent />;
  }

  return (
    <MediaUpload
      contentSetting={settings}
      medias={medias}
      uploading={uploadMediaLoading}
      progress={progress}
      slideIndex={slideIndex}
      onUpdateSlideIndex={onUpdateSlideIndex}
      editable={editable}
      onFileUploadChange={handleFileUploadChange}
      uploadParams={baseUploadParams}
    />
  );
};
