import { useState } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

import { AddCircle } from "@mui/icons-material";
import { Loader } from "@relatable/ui/Loader";
import { CustomLink } from "components/Layout/CustomLink";

interface ThumbnailProps {
  usePercentage: boolean;
  source?: string;
  url?: string;
  width: number;
  height: number;
}

export const Thumbnail = (props: ThumbnailProps): JSX.Element => {
  const { width, height, usePercentage, source, url } = props;
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Root width={width} usePercentage={usePercentage} hasSource={Boolean(source) && loaded}>
      {source === "" || error ? (
        <CustomLink url={url}>
          <NoImagePlaceholder $height={height}>
            <AddCircle className="plus-icon" />
            {error ? "Failed to load the thumbnail" : null}
          </NoImagePlaceholder>
        </CustomLink>
      ) : (
        <>
          <NoImagePlaceholder $height={height} $show={!loaded}>
            <Loader />
          </NoImagePlaceholder>
          <CustomLink show={loaded && !error} url={url}>
            <PreviewImage
              src={source}
              onLoad={() => setLoaded(true)}
              onError={() => {
                setLoaded(true);
                setError(true);
              }}
            />
          </CustomLink>
        </>
      )}
    </Root>
  );
};

const Root = styled.div<{
  usePercentage: boolean;
  hasSource: boolean;
  width: number;
}>`
  width: ${({ width, usePercentage }) => (usePercentage ? "100%" : `${width}px`)};
  max-width: 400px;
  background-color: ${({ hasSource }) => {
    if (hasSource) {
      return "#ffffff";
    }
    return "inherit";
  }};
  color: inherit;
`;

const NoImagePlaceholder = styled.div<{ $height: number; $show?: boolean }>`
  width: 100%;
  height: ${({ $height }) => `${$height}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  display: ${({ $show = true }) => ($show ? "flex" : "none")};
  font-size: 1rem;
  color: ${palette.gray.black};

  > .plus-icon {
    color: ${palette.gray[70]};
    width: 60px;
    height: 60px;
  }

  &:after {
    content: "";
    background: url(/images/relatable_pattern.svg);
    opacity: 0.08;
    top: 3px;
    left: 4px;
    bottom: 0;
    right: 0;
    position: absolute;
  }
`;

const PreviewImage = styled.img`
  align-self: center;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
