import { type FC, type PropsWithChildren, type ReactNode, useEffect } from "react";

import { Loader } from "@relatable/ui/Loader";
import { palette } from "@relatable/ui/Palette";
import { Paper } from "@relatable/ui/Paper";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AuthContextProvider } from "components/AuthContext";
import { Header } from "./Header";
import { useEncodedUser } from "./useEncodedUser";

const SignNestedLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  const { user, hash } = useEncodedUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.endsWith(hash)) navigate(`${location.pathname}/start`, { replace: true });
  }, [hash, location.pathname, navigate]);

  return (
    <>
      <Header data={user} />
      <Paper
        style={{
          margin: 20,
          padding: 20,
          textAlign: "center",
          background: palette.gray[10],
          height: "100%"
        }}
        variant="outlined"
      >
        {user ? children : <Loader />}
      </Paper>
    </>
  );
};

export const SignLayout: FC<PropsWithChildren> = ({ children }) => {
  const { campaignStub, hash } = useParams<{
    hash: string;
    campaignStub: string;
  }>();
  return (
    <AuthContextProvider userHash={hash} campaignStub={campaignStub} campaignUserHash={undefined}>
      <SignNestedLayout>{children}</SignNestedLayout>
    </AuthContextProvider>
  );
};
