import { NavigateWithParams } from "@relatable/ui/NavigateWithParams";
import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { NotFound } from "components/NotFound";
import { ApprovalLayout } from "modules/approval/components/App";
import { NoTokenUser } from "modules/approval/components/Auth/NoTokenUser";
import { PickUser } from "modules/approval/components/Auth/PickUser";
import { ContentApproval } from "modules/approval/components/ContentApproval/ContentApproval";
import { DeleteData } from "modules/approval/components/Overview/DeleteData";
import { OverviewContainer } from "modules/approval/components/Overview/OverviewContainer";
import { Resign } from "modules/resign";
import { AgreementStep } from "modules/sign/AgreementStep";
import { ApiStep } from "modules/sign/ApiStep";
import { DetailsStep } from "modules/sign/DetailsStep";
import { DoneStep } from "modules/sign/DoneStep";
import { SignLayout } from "modules/sign/Layout";
import { PaymentStep } from "modules/sign/PaymentStep";
import { StartStep } from "modules/sign/StartStep";
import { FBNoBusinessAccount } from "./modules/sign/FBNoBusinessAccount";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="" element={<NotFound />} />
      <Route
        path="/sign/:campaignStub/:hash/*"
        element={
          <SignLayout>
            <Outlet />
          </SignLayout>
        }
      >
        <Route path="fb-no-business-account" element={<FBNoBusinessAccount />} />
        <Route path="start" element={<StartStep />} />
        <Route path="api" element={<ApiStep />} />
        <Route path="details" element={<DetailsStep />} />
        <Route path="agreement" element={<AgreementStep />} />
        <Route path="payment" element={<PaymentStep />} />
        <Route path="done" element={<DoneStep />} />
      </Route>

      {/* Legacy URL */}
      <Route
        path="/preapproval/:campaignStub/:campaignUserHash/*"
        element={<NavigateWithParams to="/approval/:campaignStub/:campaignUserHash" replace />}
      />

      {/* ⬇ this one is campaign_user.id */}
      <Route
        path={"/approval/:campaignStub/:campaignUserHash/*"}
        element={
          <ApprovalLayout>
            <ContentApproval />
          </ApprovalLayout>
        }
      />

      {/* ⬇ this one is users.id */}
      <Route
        path="/overview/:userHash"
        element={
          <ApprovalLayout>
            <OverviewContainer />
          </ApprovalLayout>
        }
      />

      <Route
        path="/auth/*"
        element={
          <ApprovalLayout>
            <Outlet />
          </ApprovalLayout>
        }
      >
        <Route path="pick-user" element={<PickUser />} />
        <Route path=":userHash?" element={<NoTokenUser />} />
      </Route>

      <Route
        path="/remove/:userHash"
        element={
          <ApprovalLayout>
            <DeleteData />
          </ApprovalLayout>
        }
      />

      <Route path="/resign/:userHash" element={<Resign />} />
    </Route>
  )
);
