import styled from "@emotion/styled";
import LinkIcon from "@mui/icons-material/Link";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

export const BriefTip: React.FC<{
  briefUrl: string;
}> = ({ briefUrl }) => {
  return (
    <Tip>
      {briefUrl ? (
        <StyledLink to={briefUrl} target="_blank">
          View brief
          <LinkIcon style={{ marginLeft: 6 }} />
        </StyledLink>
      ) : (
        "Check the brief for more info"
      )}
    </Tip>
  );
};

const Tip = styled.span`
  display: block;
  text-align: center;
  font-size: 14px;
  color: ${palette.gray[60]};
  font-weight: 700;
  max-width: 300px;
  margin: 10px auto;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
